import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Countries from './ui/views/SystemSetup/Country/Country';
import Currencies from './ui/views/SystemSetup/Currency/Currency';
import PaymentSystems from './ui/views/SystemSetup/PaymentSystem/PaymentSystem';
import PaymentReceivers from './ui/views/SystemSetup/PaymentReceiver/PaymentReceiver';
import PaymentAssociation from './ui/views/SystemSetup/PaymentAssociation/PaymentAssociation';
import ProductType from './ui/views/ProductManagement/ProductType/ProductType';
import Subscription from './ui/views/ProductManagement/Subscription/Subscription';
import ContentOwner from './ui/views/ProductManagement/ContentOwner/ContentOwner';
import Movie from './ui/views/ProductManagement/Movie/Movie';
import Price from './ui/views/ProductManagement/Price/Price';
import Package from './ui/views/ProductManagement/Package/Package';
import ProductOffer from './ui/views/ProductManagement/ProductOffer/ProductOffer';
import Order from './ui/views/OrderManagemt/Order/Order';
import Account from './ui/views/AccountManagement/Account/Account';
import Transaction from './ui/views/OrderManagemt/Transaction/Transaction';
import InternalDistributor from './ui/views/PromoManagement/InternalDistributor/InternalDistributor';
import ExternalDistributor from './ui/views/PromoManagement/ExternalDistributor/ExternalDistributor';
import PromoCampaign from './ui/views/PromoManagement/PromoCampaign/PromoCampaign';
import Promo from './ui/views/PromoManagement/Promo/Promo';
import PromoDiscount from './ui/views/PromoManagement/PromoDiscount/PromoDiscount';
import PromoTypeDiscount from './ui/views/PromoManagement/PromoTypeDiscount/PromoTypeDiscount';
import MainPage from './ui/views/MainPage/MainPage';
import ProtectedRoute from './ui/components/ProtectedRoute/ProtectedRoute';
import Login from './ui/components/authentication/Login';
import SocialNetworkMapping from './ui/views/SystemSetup/SocialNetworkMapping/SocialNetworkMapping';
import ProductSet from './ui/views/ProductManagement/ProductSet/ProductSet';
import PromoAttributes from './ui/views/PromoManagement/PromoAttributes/PromoAttributes';
import Marketplace from './ui/views/ProductManagement/Marketplace/Marketplace';
import MarketplaceOrder from './ui/views/OrderManagemt/MarketplaceOrder/MarketplaceOrder';
import SubscriptionGroup from './ui/views/ProductManagement/SubscriptionGroup/SubscriptionGroup';
import MarketplaceNotification from './ui/views/OrderManagemt/MarketplaceNotification/MarketplaceNotification';
import SpecialPrice from './ui/views/AccountManagement/SpecialPrice/SpecialPrice';
// import AccessManagement from './ui/views/AccessManagement/AccessManagement';
import Users from './ui/views/AccessManagement/Users';
import Groups from './ui/views/AccessManagement/Groups';
import Permissions from './ui/views/AccessManagement/Permissions';

const VERSION_KEY = 'version_16';

const App = () => {
  if (!localStorage.getItem(VERSION_KEY)) {
    localStorage.clear();
    localStorage.setItem(VERSION_KEY, 'true');
  }
  return (
    <Routes>
      <Route
        path={'/countries'}
        element={
          <ProtectedRoute>
            <Countries />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/currencies'}
        element={
          <ProtectedRoute>
            <Currencies />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/social-network-mappings'}
        element={
          <ProtectedRoute>
            <SocialNetworkMapping />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/payment-systems'}
        element={
          <ProtectedRoute>
            <PaymentSystems />
          </ProtectedRoute>
        }
      />
      <Route path={'/login'} element={<Login />} />
      <Route
        path={'/payment-receivers'}
        element={
          <ProtectedRoute>
            <PaymentReceivers />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/payment-associations'}
        element={
          <ProtectedRoute>
            <PaymentAssociation />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/product-types'}
        element={
          <ProtectedRoute>
            <ProductType />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/subscriptions'}
        element={
          <ProtectedRoute>
            <Subscription />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/subscription-groups'}
        element={
          <ProtectedRoute>
            <SubscriptionGroup />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/content-owners'}
        element={
          <ProtectedRoute>
            <ContentOwner />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/marketplaces'}
        element={
          <ProtectedRoute>
            <Marketplace />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/movies'}
        element={
          <ProtectedRoute>
            <Movie />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/prices'}
        element={
          <ProtectedRoute>
            <Price />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/tv-packages'}
        element={
          <ProtectedRoute>
            <Package />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/product-sets'}
        element={
          <ProtectedRoute>
            <ProductSet />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/product-offers'}
        element={
          <ProtectedRoute>
            <ProductOffer />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/orders'}
        element={
          <ProtectedRoute>
            <Order />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/marketplace-orders'}
        element={
          <ProtectedRoute>
            <MarketplaceOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/marketplace-notifications'}
        element={
          <ProtectedRoute>
            <MarketplaceNotification />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/accounts'}
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/special-prices'}
        element={
          <ProtectedRoute>
            <SpecialPrice />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/accounts/:accountId'}
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/transactions'}
        element={
          <ProtectedRoute>
            <Transaction />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/internal-distributors'}
        element={
          <ProtectedRoute>
            <InternalDistributor />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/external-distributors'}
        element={
          <ProtectedRoute>
            <ExternalDistributor />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/promo-campaigns'}
        element={
          <ProtectedRoute>
            <PromoCampaign />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/promos'}
        element={
          <ProtectedRoute>
            <Promo />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/promo-attributes'}
        element={
          <ProtectedRoute>
            <PromoAttributes />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/product-discounts'}
        element={
          <ProtectedRoute>
            <PromoDiscount />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/product-type-discounts'}
        element={
          <ProtectedRoute>
            <PromoTypeDiscount />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/access-management-user'}
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/access-management-group'}
        element={
          <ProtectedRoute>
            <Groups />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/access-management-permission'}
        element={
          <ProtectedRoute>
            <Permissions />
          </ProtectedRoute>
        }
      />
      <Route
        path={'*'}
        element={
          <ProtectedRoute>
            <MainPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
