import React, { useState } from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import OneToManyDialog from '../../../components/OneToManyDialog';
import { IoIosOpen } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { prepareSelectUrl, productAvailableForPurchaseRender } from '../../../../utils';
import { FieldArray } from 'formik';
import PaginatedSelect from '../../../components/PaginatedSelect';
import AsyncSelect from '../../../components/AsyncSelect';

const Promo = () => {
  const urls = {
    list: config.PROMO,
    create: config.PROMO,
    createFromFile: config.PROMO_FROM_FILE,
    downloadFileFromResponse: true,
    update: config.PROMO_WITH_ID,
    delete: config.PROMO_WITH_ID
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [promoAttributes, setPromoAttributes] = useState([]);
  const resource = 'PROMO';
  const { t } = useTranslation();

  const customHeader = (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      variant="scrollable"
      scrollButtons="auto">
      <Tab sx={{ minHeight: '20px' }} label={t('promo')} />
      <Tab sx={{ minHeight: '20px' }} label={t('promo.from.file')} />
      <Tab sx={{ minHeight: '20px' }} label={t('promo.from.regex')} />
    </Tabs>
  );

  const discountUnits = ['CURRENCY', 'PERCENT'];
  const accountStates = ['ACTIVE', 'ON_TRIAL', 'INACTIVE'];

  const productTypeField = {
    header: t('type'),
    name: 'productType',
    type: 'select',
    required: true,
    options: {
      url: config.PRODUCT_TYPE,
      name: 'productTypeName',
      uniqueId: 'productTypeId',
      requestField: 'productTypeId'
    },
    render: ({ value }) => value && value.productTypeName,
    isEditable: true
  };

  const productField = {
    header: t('product'),
    name: 'product',
    type: 'select',
    isEditable: true,
    required: true,
    options: {
      url: config.PRODUCTS_FOR_PURCHASE,
      selectUrlParser: prepareSelectUrl,
      name: 'name',
      uniqueId: 'productId',
      requestField: 'productId',
      // TODO render in options
      render: productAvailableForPurchaseRender
    },
    render: ({ value }) => value && value.name,
    defaultFlex: 1
  };
  const countryField = {
    header: t('country'),
    name: 'country',
    required: true,
    type: 'select',
    isEditable: true,
    options: {
      url: config.COUNTRY,
      name: 'name',
      uniqueId: 'countryId',
      requestField: 'countryId'
    },
    render: ({ value }) => value && value.name,
    defaultFlex: 1
  };

  const columns = [
    {
      header: t('promo.code'),
      id: 'promoCode',
      name: 'promoCode',
      type: 'string',
      isEditable: false
    },
    {
      header: t('promo.attributes'),
      name: 'attributes',
      type: 'string',
      excludeInTable: true,
      render: ({ value }) =>
        value.length ? (
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => {
              setPromoAttributes(value);
            }}>
            <IoIosOpen />
          </IconButton>
        ) : (
          ''
        )
    },
    {
      header: t('activations'),
      name: 'numberOfActivations',
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('activations.per.account'),
      name: 'numberOfActivationsPerAccount',
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('type'),
      name: 'promoType',
      type: 'select',
      required: true,
      options: {
        data: [
          {
            promoType: 'RETAIL'
          },
          {
            promoType: 'VENDORS'
          },
          {
            promoType: 'PARTNERS'
          },
          {
            promoType: 'OTHERS'
          }
        ],
        name: 'promoType',
        uniqueId: 'promoType',
        requestField: 'promoType'
      },
      isEditable: true
    },
    {
      header: t('fulfillmentType'),
      name: 'fulfillmentType',
      type: 'select',
      required: true,
      defaultValue: 'AUTOMATIC',
      options: {
        data: [
          {
            fulfillmentType: 'AUTOMATIC'
          },
          {
            fulfillmentType: 'DEFERRED'
          }
        ],
        name: 'fulfillmentType',
        uniqueId: 'fulfillmentType',
        requestField: 'fulfillmentType'
      },
      isEditable: true
    },
    {
      header: t('promo.campaign'),
      name: 'productCampaign',
      type: 'select',
      isEditable: true,
      options: {
        url: config.PROMO_CAMPAIGN,
        selectUrlParser: prepareSelectUrl,
        name: 'promoCampaignName',
        uniqueId: 'promoCampaignId',
        requestField: 'productCampaignId'
      },
      render: ({ value }) => value && value.promoCampaignName,
      defaultFlex: 1
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      filterEditor: BoolFilter,
      isEditable: true,
      excludeInCreate: true,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },

    {
      header: t('min.order'),
      name: 'minOrderValue',
      type: 'number',
      required: true,
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('max.order'),
      name: 'maxOrderValue',
      type: 'number',
      required: true,
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('max.discount'),
      name: 'maxDiscountAmount',
      type: 'number',
      isEditable: true,
      excludeInUpdate: true,
      excludeEmptyString: true,
      excludeInTable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('valid.from'),
      name: 'validFrom',
      type: 'date',
      required: true,
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('valid.to'),
      name: 'validTo',
      required: true,
      type: 'date',
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('discounts'),
      name: 'discounts',
      type: 'select',
      options: {
        name: 'discount',
        uniqueId: {
          productTypeId: productTypeField,
          productId: productField,
          countryId: countryField,
          discountValue: 1,
          discountUnit: null,
          numActivationsPerPromo: 1
        },
        requestField: 'discounts',
        multiple: true
      },
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      sideBarRender: (values, field, user, setFieldValue) => (
        <FieldArray name={field.name}>
          {({ remove, push }) => {
            return (
              <Grid container item spacing={3}>
                {values[field.name].length > 0 &&
                  values[field.name].map((discount, index) => {
                    return (
                      <Grid container item xs={12} spacing={2} key={index}>
                        <Grid item xs={10} key={index}>
                          <Stack spacing={1}>
                            {'productTypeId' in discount ? (
                              <AsyncSelect
                                field={productTypeField}
                                required={true}
                                onChange={(fieldName, value) => {
                                  const clonedValues = [...values[field.name || field.id]];
                                  clonedValues[index]['productTypeId'] = value;
                                  setFieldValue(field.name || field.id, clonedValues);
                                }}
                                value={values[field.name || field.id][index]['productTypeId']}
                              />
                            ) : (
                              <PaginatedSelect
                                field={productField}
                                required={true}
                                onChange={(fieldName, value) => {
                                  const clonedValues = [...values[field.name || field.id]];
                                  clonedValues[index]['productId'] = value;
                                  setFieldValue(field.name || field.id, clonedValues);
                                }}
                                value={values[field.name || field.id][index]['productId']}
                              />
                            )}

                            <AsyncSelect
                              field={countryField}
                              required={true}
                              onChange={(fieldName, value) => {
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index]['countryId'] = value;
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={values[field.name || field.id][index]['countryId']}
                            />

                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {t('discount.unit')}
                              </InputLabel>

                              <Select
                                labelId="demo-simple-select-label"
                                // disabled={user.role !== 'ADMIN'}
                                value={values[field.name || field.id][index].discountUnit}
                                onChange={(event) => {
                                  const clonedValues = [...values[field.name || field.id]];
                                  clonedValues[index].discountUnit = event.target.value;
                                  setFieldValue(field.name || field.id, clonedValues);
                                }}>
                                {discountUnits.map((value) => (
                                  <MenuItem value={value} key={`${value}_${index}`}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <TextField
                              id={field.name || field.id}
                              label={t('discount.value')}
                              type={'number'}
                              inputProps={{ min: 1 }}
                              required={true}
                              // disabled={user.role !== 'ADMIN'}
                              onChange={(event) => {
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index].discountValue = parseInt(event.target.value);
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={values[field.name || field.id][index].discountValue || 1}
                              name={field.name || field.id}
                              fullWidth
                            />

                            <TextField
                              id={field.name || field.id}
                              label={t('activations.per.promo')}
                              type={'number'}
                              inputProps={{ min: 1 }}
                              // disabled={user.role !== 'ADMIN'}
                              onChange={(event) => {
                                // if (event.target.value < 1) return;
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index].numActivationsPerPromo = parseInt(
                                  event.target.value
                                );
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={
                                values[field.name || field.id][index].numActivationsPerPromo || 0
                              }
                              name={field.name || field.id}
                              fullWidth
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={2} key={`btn_${index}`}>
                          <Button
                            variant={'contained'}
                            className={'float-end'}
                            size="sm"
                            color={'error'}
                            fullWidth
                            onClick={() => {
                              remove(index);
                            }}>
                            {'X'}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Grid container item xs={12}>
                  <Grid item xs={6} pr={1}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      // disabled={user.role !== 'ADMIN'}
                      fullWidth
                      onClick={() =>
                        push({
                          productTypeId: '',
                          countryId: '',
                          discountValue: 1,
                          discountUnit: '',
                          numActivationsPerPromo: 1
                        })
                      }>
                      {t('add.type.discount')}
                    </Button>
                  </Grid>
                  <Grid item xs={6} pl={1}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      // disabled={user.role !== 'ADMIN'}
                      fullWidth
                      onClick={() =>
                        push({
                          productId: '',
                          countryId: '',
                          discountValue: 1,
                          discountUnit: '',
                          numActivationsPerPromo: 1
                        })
                      }>
                      {t('add.discount')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </FieldArray>
      )
    },
    {
      header: t('promo.attributes'),
      name: 'attributes',
      type: 'select',
      options: {
        name: 'promoAttribute',
        uniqueId: ['allowedDeviceType', 'allowedDeviceSubType', 'allowedAccountStatus'],
        requestField: 'attributes',
        multiple: true
      },
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true,
      sideBarRender: (values, field, user, setFieldValue) => (
        <FieldArray name={field.name}>
          {({ remove, push }) => {
            return (
              <Grid container item spacing={3}>
                {values[field.name].length > 0 &&
                  values[field.name].map((discount, index) => {
                    return (
                      <Grid container item xs={12} spacing={2} key={index}>
                        <Grid item xs={10} key={index}>
                          <Stack spacing={1}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {t('allowed.account.status')}
                              </InputLabel>

                              <Select
                                labelId="demo-simple-select-label"
                                // disabled={user.role !== 'ADMIN'}
                                value={values[field.name || field.id][index].allowedAccountStatus}
                                onChange={(event) => {
                                  const clonedValues = [...values[field.name || field.id]];
                                  clonedValues[index].allowedAccountStatus = event.target.value;
                                  setFieldValue(field.name || field.id, clonedValues);
                                }}>
                                {accountStates.map((value) => (
                                  <MenuItem value={value} key={`${value}_${index}`}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <TextField
                              id={field.name || field.id}
                              label={t('allowed.device.type')}
                              type={'number'}
                              required={true}
                              // disabled={user.role !== 'ADMIN'}
                              onChange={(event) => {
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index].allowedDeviceType = event.target.value;
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={values[field.name || field.id][index].allowedDeviceType}
                              name={field.name || field.id}
                              fullWidth
                            />

                            <TextField
                              id={field.name || field.id}
                              label={t('allowed.device.subtype')}
                              type={'number'}
                              // disabled={user.role !== 'ADMIN'}
                              onChange={(event) => {
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index].allowedDeviceSubType = event.target.value;
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={values[field.name || field.id][index].allowedDeviceSubType}
                              name={field.name || field.id}
                              fullWidth
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={2} key={`btn_${index}`}>
                          <Button
                            variant={'contained'}
                            className={'float-end'}
                            size="sm"
                            color={'error'}
                            fullWidth
                            onClick={() => {
                              remove(index);
                            }}>
                            {'X'}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Grid container item xs={12}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    // disabled={user.role !== 'ADMIN'}
                    fullWidth
                    onClick={() => push({})}>
                    {t('add.promo.attributes')}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </FieldArray>
      )
    }
  ];

  const creationColumnsByRegex = [
    {
      header: t('promo.code.regex'),
      name: 'promoCodeRegex',
      type: 'string',
      isEditable: true,
      excludeEmptyString: true,
      excludeInTable: true,
      excludeInUpdate: true,
      defaultFlex: 1
    },
    {
      header: t('amount.of.promos.to.generate'),
      name: 'numberOfPromosToGenerate',
      type: 'number',
      isEditable: true,
      excludeInTable: true,
      excludeInUpdate: true,
      required: true,
      defaultFlex: 1
    }
  ];

  const creationColumnsByFileName = [
    {
      header: t('file.with.promos'),
      name: 'promoFile',
      type: 'file',
      supportedFile: 'text/csv',
      isEditable: true,
      excludeInUpdate: true,
      excludeInTable: true
    }
  ];

  const creationColumnsByProvidedName = [
    {
      header: t('promo.code'),
      name: 'promoCode',
      id: 'promo.insert',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Promo code is required'),
      isEditable: true,
      excludeInTable: true,
      excludeInUpdate: true,
      required: true,
      defaultFlex: 1
    }
  ];

  const columnToConcat =
    value === 0
      ? creationColumnsByProvidedName
      : value === 1
      ? creationColumnsByFileName
      : creationColumnsByRegex;

  return (
    <>
      <Header title={t('promo.codes')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'promoCode'}
        tableId={'promoCode'}
        columns={columnToConcat.concat(columns)}
        urls={urls}
        customSidebarHeader={customHeader}
      />
      <NavBar />
      <OneToManyDialog
        columns={[
          {
            header: t('id'),
            name: 'promoAttributeId',
            defaultFlex: 1
          },
          {
            header: t('allowed.device.type'),
            name: 'allowedDeviceType',
            defaultFlex: 1
          },
          {
            header: t('allowed.device.subtype'),
            name: 'allowedDeviceSubType',
            defaultFlex: 1
          },
          {
            header: t('allowed.account.status'),
            name: 'allowedAccountStatus',
            defaultFlex: 1
          }
        ]}
        data={promoAttributes}
        open={promoAttributes.length !== 0}
        handleClose={() => {
          setPromoAttributes([]);
        }}
      />
    </>
  );
};

export default Promo;
