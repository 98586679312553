import axios from 'axios';
import { setUser } from './actions';
import { dispatch, loadState } from './store';
import config from './config';
import moment from 'moment';

export const cacheManager = {
  get: (key) => {
    const cachedConfig = localStorage.getItem(key);
    return cachedConfig && JSON.parse(cachedConfig);
  },
  set: (key, config) => {
    localStorage.setItem(key, JSON.stringify(config));
  }
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return new Promise((resolve, reject) => {
      const originalRequest = error.config;
      const user = loadState().user;
      if (
        error.response.status === 401 &&
        error.response.data &&
        user.refreshToken &&
        error.request.responseURL !== config.LOGIN
      ) {
        originalRequest._retry = true;

        const response = fetch(config.REFRESH_TOKEN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.refreshToken
          }
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              dispatch(setUser(null));
            }
          })
          .then((data) => {
            if (data === undefined) {
              return;
            }
            dispatch(
              setUser({
                ...user,
                accessToken: data.accessToken
              })
            );

            const updatedOriginalRequest = {
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: 'Bearer ' + data.accessToken
              }
            };

            return axios(updatedOriginalRequest);
          });
        resolve(response);
      } else if (
        error.response.status >= 400 &&
        error.response.status < 500 &&
        error.response.data &&
        error.response.data.status.startsWith('JWT')
      ) {
        dispatch(setUser(null));
      }

      return reject(error);
    });
  }
);

export const getRequest = (url, token) => {
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((res) => {
      return res;
    })
    .catch(() => {
      return { data: [] };
    });
};

export const getRequestWithData = (url, token, data) => {
  return axios.get(url, {
    data,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
};
// prettier-ignore
export const postRequest = (url, data, token) => {
  return axios.post(url, data, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
};

export const postRequestWithFiles = (url, data, token) => {
  return axios.post(url, data, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const putRequest = (url, data, token) => {
  return axios.put(url, data, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

export const deleteRequest = (url, token) => {
  return axios.delete(url, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

export const productAvailableForPurchaseRender = (option, field) => {
  if (option[field.options.name] && option[field.options.uniqueId]) {
    return option[field.options.name] + ' | ' + option[field.options.uniqueId];
  } else {
    return '';
  }
};

export const prepareSelectUrl = (field, value) => {
  const parsed = value.split('|')[0].trim();
  const filterValues = [
    {
      name: field.options.name,
      type: 'string',
      operator: 'contains',
      value: parsed
    }
  ];
  if (/^\d+$/.test(value)) {
    filterValues.push({
      name: field.options.requestField,
      type: 'number',
      operator: 'eq',
      value: parsed
    });
  }

  return field.options.url + '?filterValue=' + JSON.stringify(filterValues) + '&filterOperator=OR';
};

export const prepareSelectUrlForProductOfferOptions = (field, value) => {
  const parsed = value.split('|');

  const filterValues = [];

  if (parsed.length === 1) {
    const value = parsed[0].trim();
    filterValues.push(
      {
        name: 'product.name',
        type: 'string',
        operator: 'contains',
        value: value
      },
      {
        name: 'country.name',
        type: 'string',
        operator: 'contains',
        value: value
      },
      {
        name: 'country.code',
        type: 'string',
        operator: 'contains',
        value: value
      }
    );
    if (/^\d+$/.test(value)) {
      filterValues.push({
        name: 'product.productId',
        type: 'number',
        operator: 'eq',
        value: value
      });
    }
    if (/^\d+$/.test(value)) {
      filterValues.push({
        name: 'offerId',
        type: 'number',
        operator: 'eq',
        value: value
      });
    }
  } else if (parsed.length > 1) {
    const [offerId, product, country] = parsed.slice(0, 3);
    filterValues.push(
      {
        name: 'product.name',
        type: 'string',
        operator: 'contains',
        value: product.trim()
      },
      {
        name: 'country.name',
        type: 'string',
        operator: 'contains',
        value: country.trim()
      },
      {
        name: 'country.code',
        type: 'string',
        operator: 'contains',
        value: country.trim()
      }
    );
    if (/^\d+$/.test(product)) {
      filterValues.push({
        name: 'product.productId',
        type: 'number',
        operator: 'eq',
        value: product
      });
    }
    if (/^\d+$/.test(offerId)) {
      filterValues.push({
        name: 'offerId',
        type: 'number',
        operator: 'eq',
        value: offerId
      });
    }
  }

  return field.options.url + '?filterValue=' + JSON.stringify(filterValues) + '&filterOperator=OR';
};

export const formatDate = (dateStr) => {
  if (dateStr && !isNaN(new Date(dateStr))) {
    return moment(dateStr).format('YYYY-MM-DD-HH:mm');
  }
  return '';
};

export const renamed = {
  CURRENCY: 'Fixed discount',
  PERCENT: 'Relative discount',
  PRICE_OVERRIDE: 'Price override'
};

export const discountUnitRender = (option) => {
  return renamed[option.discountUnit];
};

export const getRole = (iamResource) => {
  const permissions = {};
  permissions.viewer = ['ADMIN', 'VIEWER', 'CREATOR', 'EDITOR'].includes(iamResource) || false;
  permissions.editor = ['ADMIN', 'EDITOR'].includes(iamResource) || false;
  permissions.creator = ['ADMIN', 'CREATOR'].includes(iamResource) || false;
  permissions.admin = iamResource === 'ADMIN';
  return permissions;
};
