import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Tab,
  Tabs
} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import IconButton from '@mui/material/IconButton';
import { FaDoorClosed, FaDoorOpen } from 'react-icons/fa';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../components/TabPanel';
import { getRequest, postRequest, deleteRequest, getRole } from '../../../utils';
import config from '../../../config';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useSelector } from 'react-redux';

const UserCard = ({ row, open, handleClose, iamResource }) => {
  if (!open || Object.keys(row).length === 0) return;
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [permissionListData, setPermissionListData] = useState([]);
  const [listUserPermission, setListUserPermission] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.permissions[iamResource]);
  const permissions = getRole(role);

  const getListUserPermission = () => {
    row.user_id &&
      getRequest(
        config.ACCESS_MANAGEMENT_PERMISSION_USER_WITH_ID(row.user_id),
        user.accessToken
      ).then((res) => {
        const listUserPermission = res?.data?.filter((item) => item.is_active);
        setListUserPermission(listUserPermission || []);
      });
  };

  const deleteUserPermission = (data) => {
    deleteRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_DELETE_USER_PERMISSION(data.permission_id, row.user_id),
      user.accessToken
    ).finally(() => getListUserPermission());
  };

  useEffect(() => {
    value === 0 && getListUserPermission();
    value === 1 && getListPermissionToAdd();
  }, [value]);

  useEffect(() => {
    if (!open) {
      setValue(0);
      setPermissionListData([]);
      setListUserPermission([]);
    }
  }, [open]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const _handleClose = () => {
    handleClose();
  };

  const getListPermissionToAdd = async () => {
    // делаем выборку активных доступов не добавленных в группу
    const listPermission = await getRequest(
      config.ACCESS_MANAGEMENT_PERMISSION,
      user.accessToken
    ).then((res) => [...res.data]);
    const listPermissionInGroup = [];
    await getRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_USER_WITH_ID(row.user_id),
      user.accessToken
    ).then((res) => {
      res?.data?.forEach((item) => {
        if (item.is_active && item.permission.permission_id) {
          listPermissionInGroup.push(item.permission.permission_id);
        }
      });
    });
    let listUserToAdd;
    listUserToAdd = listPermission.filter(
      (item) => item.is_active && !listPermissionInGroup.includes(item.permission_id)
    );
    setPermissionListData(listUserToAdd || []);
  };

  const addUserPermission = (data) => {
    postRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_USER_WITH_ID(row.user_id),
      {
        permission_id: data.permission_id,
        is_active: data.is_active
      },
      user.accessToken
    ).finally(() => {
      getListPermissionToAdd();
    });
  };

  const gridStyle = { minHeight: isFullscreen ? '74vh' : '22vh' };
  return (
    <Dialog
      fullScreen={isFullscreen}
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={_handleClose}>
      <DialogActions>
        <Button onClick={changeFullscreen} hide>
          <IconButton>
            {!isFullscreen ? (
              <OpenInFullIcon color="primary" />
            ) : (
              <CloseFullscreenIcon color="primary" />
            )}
          </IconButton>
        </Button>
      </DialogActions>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example">
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('permission.list')}
                icon={<FaDoorClosed />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('permission.add')}
                icon={<FaDoorOpen />}
                iconPosition={'start'}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('id'),
                  name: 'permission_id',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.permission.permission_id;
                  },
                  defaultFlex: 1
                },
                {
                  header: t('iamResource'),
                  name: 'iam_resource',
                  type: 'string',
                  defaultFlex: 2,
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.permission.iam_resource;
                  },
                  isEditable: true
                },
                {
                  header: t('iamRole'),
                  name: 'iam_role',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.permission.iam_role;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('is.active'),
                  name: 'is_active',
                  type: 'boolean',
                  filterEditor: BoolFilter,
                  render: ({ value }) => <Switch disabled checked={value} />,
                  defaultFlex: 2,
                  excludeInFilter: true,
                  isEditable: true
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('dateModify'),
                  name: 'date_modified',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('dateDelete'),
                  name: 'date_deleted',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'permission',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  render: ({ value }) => (
                    <Button onClick={() => deleteUserPermission(value)}>Delete</Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={listUserPermission}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('iamResource'),
                  name: 'iam_resource',
                  type: 'number',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('iamRole'),
                  name: 'iam_role',
                  type: 'number',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('is.active'),
                  name: 'is_active',
                  type: 'boolean',
                  filterEditor: BoolFilter,
                  render: ({ value }) => <Switch disabled checked={value} />,
                  defaultFlex: 1,
                  excludeInFilter: true,
                  isEditable: true
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'permission',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  // prettier-ignore
                  render: ({ data }) => (
                    <Button onClick={() => addUserPermission(data)}>Add</Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={permissionListData}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserCard;
