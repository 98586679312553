import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prepareSelectUrl, productAvailableForPurchaseRender } from '../../../../utils';

const Price = () => {
  const urls = {
    list: config.PRICE,
    create: config.PRICE,
    delete: config.PRICE_WITH_ID,
    treatUpdateAsCreate: true
  };
  const resource = 'OFFER';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'priceId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('offerID'),
      name: 'offerId',
      type: 'number',
      filterEditor: NumberFilter,
      render: ({ data }) => data && data.productOffer.offerId
    },
    {
      header: t('product'),
      name: 'product',
      type: 'select',
      required: true,
      isEditable: true,
      options: {
        url: config.PRODUCT,
        selectUrlParser: prepareSelectUrl,
        name: 'name',
        uniqueId: 'productId',
        requestField: 'productId',
        // TODO render in options
        render: productAvailableForPurchaseRender
      },
      render: ({ data }) => data && data.productOffer.product.name,
      defaultFlex: 1
    },
    {
      header: t('price'),
      name: 'decimalPrice',
      required: true,
      isEditable: true,
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('trial.price'),
      name: 'trialDecimalPrice',
      isEditable: true,
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'select',
      options: {
        url: config.CURRENCY,
        name: 'currencyName',
        uniqueId: 'currencyId',
        requestField: 'currencyId'
      },
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('state'),
      name: 'state',
      type: 'string',
      validationSchema: Yup.string().max(255).required('State is required')
    },
    {
      header: t('valid.from'),
      name: 'validFrom',
      type: 'date',
      isEditable: true,
      required: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('valid.to'),
      name: 'validTo',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    }
  ];

  return (
    <>
      <Header title={t('prices')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'priceId'}
        tableId={'price'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Price;
