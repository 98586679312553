const INITIAL_STATE = {
  openedTables: [],
  selectedRow: null,
  user: null,
  permissions: null
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'SET_OPENED_TABLES':
      return {
        ...state,
        openedTables: action.payload
      };
    case 'SET_SELECTED_ROW':
      return {
        ...state,
        selectedRow: action.payload
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'SET_PERMISSION':
      return {
        ...state,
        permissions: action.payload
      };
    case 'SET_DEFAULT':
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
