import React from 'react';
import { Chip, Popover } from '@mui/material';
import { BsArrowRight } from 'react-icons/bs';
import { IoInformationCircleSharp } from 'react-icons/io5';

const HistoryPopover = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IoInformationCircleSharp
        style={{ cursor: 'pointer' }}
        size={30}
        aria-describedby={id}
        onClick={handleClick}
      />
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        {Object.entries(value).map(([k, v]) => {
          return (
            <div key={k + JSON.stringify(v)} style={{ margin: 10 }}>
              <span style={{ marginRight: 5 }}>{k}:</span>
              <Chip label={v.oldValue + ''} color="error" variant="outlined" />
              <BsArrowRight style={{ marginLeft: 5, marginRight: 5 }} />
              <Chip label={v.newValue + ''} color="primary" variant="outlined" />
            </div>
          );
        })}
      </Popover>
    </div>
  );
};

export default HistoryPopover;
