const BACKEND_URL = (window._env_ && window._env_.BACKEND_URL) || 'http://localhost:8080';
const BACKEND_URL_GO = (window._env_ && window._env_.BACKEND_URL_GO) || 'http://localhost:8080';
const OLD_BILLING_URL = (window._env_ && window._env_.OLD_BILLING_URL) || 'http://localhost:8080';
// const BACKEND_URL = 'https://billing-v2-dev.sweet.tv';
// const BACKEND_URL_GO = 'https://go.billing-v2-dev.sweet.tv';
// const OLD_BILLING_URL = 'https://billing.dev.sweet.tv';
const API = '/api';
const V1 = '/v1';
const V2 = '/v2';

const USER_DEVICE_LIST = OLD_BILLING_URL + V1 + '/user/device-list';

const SYSTEM_SETUP = BACKEND_URL + API + V1 + '/system-setup';
const PRODUCT_MANAGEMENT = BACKEND_URL + API + V1 + '/product-management';
// const PRODUCT_MANAGEMENT_GO = BACKEND_URL_GO + API + V2 + '/product-management';
const PROMO_MANAGEMENT = BACKEND_URL + API + V1 + '/promo-management';
// const PROMO_MANAGEMENT = BACKEND_URL_GO + API + V2 + '/promo-management';
const ORDER_MANAGEMENT = BACKEND_URL + API + V1 + '/order-management';
// const ORDER_MANAGEMENT_GO = BACKEND_URL_GO + API + V2 + '/order-management';

const LOGS = BACKEND_URL + API + V1 + '/audit-log';
const LOGIN = BACKEND_URL_GO + API + V2 + '/auth/login';
const REFRESH_TOKEN = BACKEND_URL_GO + API + V2 + '/auth/refresh';

const ACCESS_REVOKE = BACKEND_URL_GO + API + V2 + '/auth/access-revoke';
const REFRESH_REVOKE = BACKEND_URL_GO + API + V2 + '/auth/refresh-revoke';

const COUNTRY = SYSTEM_SETUP + '/country';
const COUNTRY_WITH_ID = (countryId) => COUNTRY + '/' + countryId;

const CURRENCY = SYSTEM_SETUP + '/currency';
const CURRENCY_WITH_ID = (currencyId) => CURRENCY + '/' + currencyId;

const SOCIAL_NETWORK_MAPPING = SYSTEM_SETUP + '/social-network-mapping';
const SOCIAL_NETWORK_MAPPING_WITH_ID = (sncId) => SOCIAL_NETWORK_MAPPING + '/' + sncId;

const PAYMENT_SYSTEM = SYSTEM_SETUP + '/payment-system';
const PAYMENT_SYSTEM_WITH_ID = (paymentSystemId) => PAYMENT_SYSTEM + '/' + paymentSystemId;

const PAYMENT_RECEIVER = SYSTEM_SETUP + '/payment-receiver';
const PAYMENT_RECEIVER_WITH_ID = (paymentReceiverId) => PAYMENT_RECEIVER + '/' + paymentReceiverId;

const PAYMENT_ASSOCIATION = SYSTEM_SETUP + '/payment-association';
const PAYMENT_ASSOCIATION_WITH_ID = (paymentAssociationId) =>
  PAYMENT_ASSOCIATION + '/' + paymentAssociationId;
const PRODUCT_TYPE = PRODUCT_MANAGEMENT + '/product-type';
const PRODUCT_TYPE_WITH_ID = (productTypeId) => PRODUCT_TYPE + '/' + productTypeId;

const SUBSCRIPTION = PRODUCT_MANAGEMENT + '/subscription';
const SUBSCRIPTION_WITH_ID = (subscriptionId) => SUBSCRIPTION + '/' + subscriptionId;

const SUBSCRIPTION_GROUP = PRODUCT_MANAGEMENT + '/subscription-group';
const SUBSCRIPTION_GROUP_WITH_ID = (subscriptionGroupId) =>
  SUBSCRIPTION_GROUP + '/' + subscriptionGroupId;

const CONTENT_OWNER = PRODUCT_MANAGEMENT + '/content-owner';
const CONTENT_OWNER_WITH_ID = (contentOwnerId) => CONTENT_OWNER + '/' + contentOwnerId;

const PRICE = PRODUCT_MANAGEMENT + '/price';
const PRICE_WITH_ID = (priceId) => PRICE + '/' + priceId;

const MARKETPLACE_CONNECTION = PRODUCT_MANAGEMENT + '/marketplace-connection';
const MARKETPLACE_CONNECTION_WITH_ID = (productId) => MARKETPLACE_CONNECTION + '/' + productId;

const MOVIE = PRODUCT_MANAGEMENT + '/movie';
const MOVIE_WITH_ID = (productId) => MOVIE + '/' + productId;

const PRODUCT = PRODUCT_MANAGEMENT + '/product';
const PRODUCT_WITH_ID = (productId) => PRODUCT + '/' + productId;

const PRODUCTS_FOR_PURCHASE = PRODUCT + '/available-for-purchase';

const PACKAGE = PRODUCT_MANAGEMENT + '/tv-package';
const PACKAGE_WITH_ID = (package_id) => PACKAGE + '/' + package_id;

const PRODUCT_SET = PRODUCT_MANAGEMENT + '/product-set';
const PRODUCT_SET_WITH_ID = (package_id) => PRODUCT_SET + '/' + package_id;

const PRODUCT_OFFER = PRODUCT_MANAGEMENT + '/product-offer';
const PRODUCT_OFFER_WITH_ID = (offerId) => PRODUCT_OFFER + '/' + offerId;

const EXTERNAL_DISTRIBUTOR = PROMO_MANAGEMENT + '/external-distributor';
const EXTERNAL_DISTRIBUTOR_WITH_ID = (externalDistributorId) =>
  EXTERNAL_DISTRIBUTOR + '/' + externalDistributorId;

const INTERNAL_DISTRIBUTOR = PROMO_MANAGEMENT + '/internal-distributor';
const INTERNAL_DISTRIBUTOR_WITH_ID = (internalDistributorId) =>
  INTERNAL_DISTRIBUTOR + '/' + internalDistributorId;

const PROMO_CAMPAIGN = PROMO_MANAGEMENT + '/promo-campaign';
const PROMO_CAMPAIGN_WITH_ID = (promoCampaignId) => PROMO_CAMPAIGN + '/' + promoCampaignId;

const PROMO = PROMO_MANAGEMENT + '/promo';
const PROMO_FROM_FILE = PROMO + '/from-file';
const PROMO_WITH_ID = (promoId) => PROMO + '/' + promoId;

const PROMO_ATTRIBUTES = PROMO + '/attribute';
const PROMO_ATTRIBUTES_WITH_ID = (promoAttributeId) => PROMO_ATTRIBUTES + '/' + promoAttributeId;

const PRODUCT_DISCOUNT = PROMO_MANAGEMENT + '/product-discount';
const PRODUCT_DISCOUNT_WITH_ID = (productDiscountId) => PRODUCT_DISCOUNT + '/' + productDiscountId;

const PRODUCT_DISCOUNT_TYPE = PROMO_MANAGEMENT + '/product-type-discount';
const PRODUCT_DISCOUNT_TYPE_WITH_ID = (productTypeDiscountId) =>
  PRODUCT_DISCOUNT_TYPE + '/' + productTypeDiscountId;

const TRANSACTION = ORDER_MANAGEMENT + '/transaction';
const TRANSACTION_WITH_ID = (orderId) => TRANSACTION + '/' + orderId;

const ORDER = ORDER_MANAGEMENT + '/order';
const ORDER_WITH_ID = (orderId) => ORDER + '/' + orderId;

const MARKETPLACE_ORDER = ORDER_MANAGEMENT + '/marketplace-order';
const MARKETPLACE_ORDER_WITH_ID = (orderId) => MARKETPLACE_ORDER + '/' + orderId;

const MARKETPLACE = ORDER_MANAGEMENT + '/marketplace';
const MARKETPLACE_NOTIFICATION = MARKETPLACE + '/server-notification';

const ACCOUNT_MANAGEMENT = BACKEND_URL + API + V1 + '/account-management';
const ACCOUNT = ACCOUNT_MANAGEMENT + '/account';
const ACCOUNT_WITH_ID = (accountId) => ACCOUNT + '/' + accountId;

const ACCOUNT_SMART_SEARCH = ACCOUNT + '/search';

const ACCOUNT_SOCIAL_NETWORK = ACCOUNT_MANAGEMENT + '/social-network';
const ACCOUNT_SOCIAL_NETWORK_WITH_ID = (socialNetworkRegistrationId) =>
  ACCOUNT_SOCIAL_NETWORK + '/' + socialNetworkRegistrationId;

const SPECIAL_OFFER_PRICE = ACCOUNT_MANAGEMENT + '/offer/special-price';
const SPECIAL_OFFER_PRICE_WITH_ID = (accountOfferPriceId) =>
  SPECIAL_OFFER_PRICE + '/' + accountOfferPriceId;
const SPECIAL_OFFER_PRICE_REMOVE_BY_OFFER_ID = (accountOfferId) =>
  SPECIAL_OFFER_PRICE + '/remove-by-offer/' + accountOfferId;

const ACCESS_MANAGEMENT = BACKEND_URL + API + V1 + '/access-management';
const ACCESS_MANAGEMENT_USER = ACCESS_MANAGEMENT + '/user';
const ACCESS_MANAGEMENT_USER_UPDATE = (id) => ACCESS_MANAGEMENT_USER + '/' + id;
const ACCESS_MANAGEMENT_USER_DELETE = (id) => ACCESS_MANAGEMENT_USER + '/' + id;
const ACCESS_MANAGEMENT_USER_WITH_ID = (id) => ACCESS_MANAGEMENT_USER + '/' + id;
const ACCESS_MANAGEMENT_USER_CHANGE_PASSWORD = (id) =>
  ACCESS_MANAGEMENT_USER + '/' + id + '/password';

const ACCESS_MANAGEMENT_GROUP = ACCESS_MANAGEMENT + '/group';
const ACCESS_MANAGEMENT_GROUP_UPDATE = (id) => ACCESS_MANAGEMENT_GROUP + '/' + id;
const ACCESS_MANAGEMENT_GROUP_DEACTIVATE = (id) => ACCESS_MANAGEMENT_GROUP + '/' + id;

const ACCESS_MANAGEMENT_PERMISSION = ACCESS_MANAGEMENT + '/permission';
const ACCESS_MANAGEMENT_PERMISSION_DELETE = (id) => ACCESS_MANAGEMENT_PERMISSION + '/' + id;
const ACCESS_MANAGEMENT_PERMISSION_GROUP = ACCESS_MANAGEMENT_PERMISSION + '/group';
const ACCESS_MANAGEMENT_PERMISSION_GROUP_WITH_ID = (id) =>
  ACCESS_MANAGEMENT_PERMISSION_GROUP + '/' + id;

const ACCESS_MANAGEMENT_LIST_USER_IN_GROUP = (id) => ACCESS_MANAGEMENT_USER + '/group/' + id;
const ACCESS_MANAGEMENT_ADD_USER_TO_GROUP = (id) => ACCESS_MANAGEMENT_USER + '/group/' + id;
const ACCESS_MANAGEMENT_ADD_PERMISSION_TO_GROUP = (id) =>
  ACCESS_MANAGEMENT_PERMISSION_GROUP + '/' + id;
const ACCESS_MANAGEMENT_DELETE_USER_FROM_GROUP = (userId, groupId) =>
  ACCESS_MANAGEMENT_USER + `/${userId}/group/${groupId}`;
const ACCESS_MANAGEMENT_PERMISSION_DELETE_GROUP_PERMISSION = (permissionId, groupId) =>
  ACCESS_MANAGEMENT_PERMISSION + `/${permissionId}/group/${groupId}`;
const ACCESS_MANAGEMENT_PERMISSION_USER_WITH_ID = (id) =>
  ACCESS_MANAGEMENT_PERMISSION + '/user/' + id;
const ACCESS_MANAGEMENT_PERMISSION_DELETE_USER_PERMISSION = (permissionId, userId) =>
  ACCESS_MANAGEMENT_PERMISSION + `/${permissionId}/user/${userId}`;

const ACCESS_MANAGEMENT_PERMISSION_GET_USER_DIRECT_PERMISSION = (id) =>
  ACCESS_MANAGEMENT_PERMISSION + `/access-rules/user/${id}?withIndirectPermission=true`;

const DEVICE_TYPE = {
  0: 'DT_Unknown',
  1: 'DT_DIB_120',
  2: 'DT_IPTV_Player',
  7: 'DT_MAG200',
  8: 'DT_MAG250_Micro',
  9: 'DT_MAG250_Mini',
  10: 'DT_Himedia_HD600A',
  11: 'DT_Android_Player',
  12: 'DT_STB_Emul',
  13: 'DT_SmartTV',
  14: 'DT_iNext',
  15: 'DT_M3U',
  16: 'DT_AndroidTV',
  17: 'DT_IOS_Player',
  18: 'DT_MacOS_Player',
  19: 'DT_Kivi_TV',
  20: 'DT_GX_STB',
  21: 'DT_NOMI_TV',
  22: 'DT_Web_Browser',
  23: 'DT_ERGO_TV',
  24: 'DT_AppleTV',
  25: 'DT_Xbox'
};

export const menu = (permissions) => {
  // ('ACCOUNT', 'ORDER', 'PAYMENT', 'TRANSACTION', 'PRODUCT',
  // 'PROMO', 'PROMO_DISCOUNT',
  // 'PROMO_DISTRIBUTOR', 'OFFER', 'SYSTEM', 'ACCESS', 'AUTH')
  // type:
  // VIEWER, EDITOR, CREATOR, ADMIN
  const listAvailableSections = Object.keys(permissions);
  const offer = listAvailableSections.includes('OFFER')
    ? [
        {
          name: 'prices',
          href: '/prices'
        },
        {
          name: 'subscriptions',
          href: '/subscriptions'
        },
        {
          name: 'product.offers',
          href: '/product-offers'
        }
      ]
    : [];
  const account = listAvailableSections.includes('ACCOUNT')
    ? [
        {
          name: 'social.network.mapping',
          href: '/social-network-mappings'
        },
        {
          name: 'accounts',
          href: '/accounts'
        }
      ]
    : [];
  const product = listAvailableSections.includes('PRODUCT')
    ? [
        {
          name: 'content.owners',
          href: '/content-owners'
        },
        {
          name: 'movies',
          href: '/movies'
        },
        {
          name: 'tv.packages',
          href: '/tv-packages'
        },
        {
          name: 'product.types',
          href: '/product-types'
        },
        {
          name: 'subscription.groups',
          href: '/subscription-groups'
        },
        {
          name: 'subscriptions',
          href: '/subscriptions'
        },
        {
          name: 'promo.attributes',
          href: '/promo-attributes'
        },
        {
          name: 'promo.campaigns',
          href: '/promo-campaigns'
        },
        {
          name: 'product.sets',
          href: '/product-sets'
        }
      ]
    : [];
  const system = listAvailableSections.includes('SYSTEM')
    ? [
        {
          name: 'special.prices',
          href: '/special-prices'
        },
        {
          name: 'countries',
          href: '/countries'
        },
        {
          name: 'currencies',
          href: '/currencies'
        }
      ]
    : [];
  const payment = listAvailableSections.includes('PAYMENT')
    ? [
        {
          name: 'payment.associations',
          href: '/payment-associations'
        },
        {
          name: 'payment.systems',
          href: '/payment-systems'
        },
        {
          name: 'payment.receivers',
          href: '/payment-receivers'
        }
      ]
    : [];
  const order = listAvailableSections.includes('ORDER')
    ? [
        {
          name: 'orders',
          href: '/orders'
        },
        {
          name: 'marketplaces',
          href: '/marketplaces'
        },
        {
          name: 'marketplace.notification',
          href: '/marketplace-notifications'
        },
        {
          name: 'marketplace.orders',
          href: '/marketplace-orders'
        }
      ]
    : [];
  const transaction = listAvailableSections.includes('TRANSACTION')
    ? [
        {
          name: 'transactions',
          href: '/transactions'
        }
      ]
    : [];
  const access = listAvailableSections.includes('ACCESS')
    ? [
        {
          name: 'access.management.permission',
          href: '/access-management-permission'
        },
        {
          name: 'access.management.group',
          href: '/access-management-group'
        },
        {
          name: 'access.management.user',
          href: '/access-management-user'
        }
      ]
    : [];
  const promo = listAvailableSections.includes('PROMO')
    ? [
        {
          name: 'product.discounts',
          href: '/product-discounts'
        },
        {
          name: 'product.type.discounts',
          href: '/product-type-discounts'
        },
        {
          name: 'promo.codes',
          href: '/promos'
        }
      ]
    : [];
  const promoDiscount = listAvailableSections.includes('PROMO_DISCOUNT') ? [] : [];
  const promoDistributor = listAvailableSections.includes('PROMO_DISTRIBUTOR')
    ? [
        {
          name: 'external.distributors',
          href: '/external-distributors'
        },
        {
          name: 'internal.distributors',
          href: '/internal-distributors'
        }
      ]
    : [];
  return [
    ...account,
    ...product,
    ...system,
    ...payment,
    ...offer,
    ...order,
    ...transaction,
    ...access,
    ...promo,
    ...promoDiscount,
    ...promoDistributor
  ];
};

const config = {
  COUNTRY,
  COUNTRY_WITH_ID,
  CURRENCY,
  CURRENCY_WITH_ID,
  SOCIAL_NETWORK_MAPPING,
  SOCIAL_NETWORK_MAPPING_WITH_ID,
  PAYMENT_SYSTEM,
  PAYMENT_SYSTEM_WITH_ID,
  PAYMENT_RECEIVER,
  PAYMENT_RECEIVER_WITH_ID,
  PAYMENT_ASSOCIATION,
  PAYMENT_ASSOCIATION_WITH_ID,
  PRODUCT_TYPE,
  PRODUCT_TYPE_WITH_ID,
  SUBSCRIPTION,
  SUBSCRIPTION_WITH_ID,
  PRODUCTS_FOR_PURCHASE,
  CONTENT_OWNER,
  CONTENT_OWNER_WITH_ID,
  PRICE,
  PRICE_WITH_ID,
  PRODUCT,
  PRODUCT_WITH_ID,
  PRODUCT_SET,
  PRODUCT_SET_WITH_ID,
  MARKETPLACE_CONNECTION,
  MARKETPLACE_CONNECTION_WITH_ID,
  MOVIE,
  MOVIE_WITH_ID,
  PACKAGE,
  PACKAGE_WITH_ID,
  PRODUCT_OFFER,
  PRODUCT_OFFER_WITH_ID,
  EXTERNAL_DISTRIBUTOR,
  EXTERNAL_DISTRIBUTOR_WITH_ID,
  INTERNAL_DISTRIBUTOR,
  INTERNAL_DISTRIBUTOR_WITH_ID,
  PROMO_CAMPAIGN,
  PROMO_CAMPAIGN_WITH_ID,
  PROMO,
  PROMO_FROM_FILE,
  PROMO_WITH_ID,
  PROMO_ATTRIBUTES,
  PROMO_ATTRIBUTES_WITH_ID,
  PRODUCT_DISCOUNT,
  PRODUCT_DISCOUNT_WITH_ID,
  PRODUCT_DISCOUNT_TYPE,
  PRODUCT_DISCOUNT_TYPE_WITH_ID,
  ORDER,
  ORDER_WITH_ID,
  MARKETPLACE_ORDER,
  MARKETPLACE_ORDER_WITH_ID,
  TRANSACTION,
  TRANSACTION_WITH_ID,
  ACCOUNT,
  ACCOUNT_SMART_SEARCH,
  ACCOUNT_WITH_ID,
  ACCOUNT_SOCIAL_NETWORK_WITH_ID,
  LOGS,
  LOGIN,
  ACCESS_REVOKE,
  REFRESH_REVOKE,
  REFRESH_TOKEN,
  SUBSCRIPTION_GROUP,
  SUBSCRIPTION_GROUP_WITH_ID,
  MARKETPLACE_NOTIFICATION,
  SPECIAL_OFFER_PRICE,
  SPECIAL_OFFER_PRICE_WITH_ID,
  SPECIAL_OFFER_PRICE_REMOVE_BY_OFFER_ID,
  USER_DEVICE_LIST,
  DEVICE_TYPE,
  ACCESS_MANAGEMENT_USER,
  ACCESS_MANAGEMENT_USER_UPDATE,
  ACCESS_MANAGEMENT_USER_DELETE,
  ACCESS_MANAGEMENT_USER_WITH_ID,
  ACCESS_MANAGEMENT_USER_CHANGE_PASSWORD,
  ACCESS_MANAGEMENT_GROUP,
  ACCESS_MANAGEMENT_GROUP_UPDATE,
  ACCESS_MANAGEMENT_GROUP_DEACTIVATE,
  ACCESS_MANAGEMENT_PERMISSION_GROUP_WITH_ID,
  ACCESS_MANAGEMENT_PERMISSION,
  ACCESS_MANAGEMENT_PERMISSION_DELETE,
  ACCESS_MANAGEMENT_LIST_USER_IN_GROUP,
  ACCESS_MANAGEMENT_ADD_USER_TO_GROUP,
  ACCESS_MANAGEMENT_DELETE_USER_FROM_GROUP,
  ACCESS_MANAGEMENT_PERMISSION_DELETE_GROUP_PERMISSION,
  ACCESS_MANAGEMENT_ADD_PERMISSION_TO_GROUP,
  ACCESS_MANAGEMENT_PERMISSION_USER_WITH_ID,
  ACCESS_MANAGEMENT_PERMISSION_DELETE_USER_PERMISSION,
  ACCESS_MANAGEMENT_PERMISSION_GET_USER_DIRECT_PERMISSION
};

export default config;
