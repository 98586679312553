import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import { useTranslation } from 'react-i18next';

const PaymentAssociation = () => {
  const urls = {
    list: config.PAYMENT_ASSOCIATION,
    create: config.PAYMENT_ASSOCIATION,
    update: config.PAYMENT_ASSOCIATION_WITH_ID,
    delete: config.PAYMENT_ASSOCIATION_WITH_ID
  };
  const resource = 'PAYMENT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'paymentAssociationId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('country'),
      name: 'country',
      type: 'select',
      isEditable: true,
      options: {
        url: config.COUNTRY,
        name: 'name',
        uniqueId: 'countryId',
        requestField: 'countryId'
      },
      render: ({ value }) => value && value.name,
      required: true,
      defaultFlex: 1
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'select',
      options: {
        url: config.CURRENCY,
        name: 'currencyName',
        uniqueId: 'currencyId',
        requestField: 'currencyId'
      },
      render: ({ value }) => value && value.currencyName,
      required: true,
      isEditable: true
    },
    {
      header: t('payment.system'),
      name: 'paymentSystem',
      type: 'select',
      options: {
        url: config.PAYMENT_SYSTEM,
        name: 'paymentSystemName',
        uniqueId: 'paymentSystemId',
        requestField: 'paymentSystemId'
      },
      render: ({ value }) => value && value.paymentSystemName,
      required: true,
      isEditable: true
    },
    {
      header: t('payment.receiver'),
      name: 'paymentReceiver',
      type: 'select',
      options: {
        url: config.PAYMENT_RECEIVER,
        name: 'paymentReceiverName',
        uniqueId: 'paymentReceiverId',
        requestField: 'paymentReceiverId'
      },
      render: ({ value }) => value && value.paymentReceiverName,
      required: true,
      isEditable: true
    },
    {
      header: t('is.default'),
      name: 'isDefault',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('payment.associations')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'paymentAssociationId'}
        tableId={'paymentAssociation'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default PaymentAssociation;
