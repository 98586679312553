import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import { getRequest } from '../../utils';
import { useSelector } from 'react-redux';

export default function PaginatedSelect({ field, value, onChange, readOnly, required }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(field.options.data || []);
  const [inputValue, setInputValue] = React.useState('');

  const user = useSelector((state) => state.user);

  const fetch = React.useMemo(
    () =>
      debounce((request) => {
        const url = field.options.selectUrlParser(field, request?.input[field.name]);
        getRequest(url, user.accessToken).then((res) => {
          setOptions(res.data?.data);
        });
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (inputValue[field.name] === '' || inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      getOptionDisabled={(option) => {
        return option.isActive !== undefined ? !option.isActive : false;
      }}
      // autoComplete
      // includeInputInList
      // filterSelectedOptions
      onInputChange={(event, newInputValue) => {
        setInputValue(
          typeof newInputValue === 'object'
            ? newInputValue
            : {
                [field.name]: newInputValue
              }
        );
      }}
      id={field.name}
      type={field.name}
      multiple={field.options.multiple}
      disabled={readOnly}
      open={open}
      value={
        typeof value === 'object'
          ? value
          : {
              [field.name]: value
            }
      }
      onChange={(e, v) => {
        setOptions(v ? [v, ...options] : options);
        onChange(field.name, v);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, valueForCompare) => {
        return (
          option[field.options.uniqueId] === valueForCompare[field.options.uniqueId] ||
          option[field.options.uniqueId] === valueForCompare
        );
      }}
      getOptionLabel={(option) => {
        return field.options.render !== undefined
          ? field.options.render(option, field)
          : option[field.options.name] || '';
      }}
      options={options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[field.options.uniqueId]}>
            {field.options.render !== undefined
              ? field.options.render(option, field)
              : option[field.options.name]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.header}
          required={required}
          InputProps={{
            ...params.InputProps,
            form: {
              autocomplete: 'off'
            },
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
          }}
        />
      )}
    />
  );
}
