import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Movie = () => {
  const urls = {
    list: config.MOVIE,
    create: config.MOVIE,
    update: config.MOVIE_WITH_ID,
    delete: config.MOVIE_WITH_ID
  };
  const resource = 'PRODUCT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'movieId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('external.id'),
      name: 'movieExternalId',
      required: true,
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('name'),
      name: 'name',
      required: true,
      type: 'string',
      validationSchema: Yup.string().max(255).required('Name is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('type'),
      name: 'productType',
      type: 'select',
      render: ({ value }) => value && value.productTypeName,
      isEditable: false
    },
    {
      header: t('description'),
      type: 'string',
      validationSchema: Yup.string().max(255).required('Description is required'),
      isEditable: true,
      name: 'description'
    },
    {
      header: t('product.id'),
      name: 'productId',
      id: 'productId',
      type: 'number',
      render: ({ data }) => data && data.productId,
      filterEditor: NumberFilter
    },
    {
      header: t('quality'),
      name: 'quality',
      type: 'select',
      required: true,
      options: {
        data: [
          {
            quality: 'SD'
          },
          {
            quality: 'HD'
          },
          {
            quality: '4K'
          }
        ],
        name: 'quality',
        uniqueId: 'quality',
        requestField: 'quality'
      },
      isEditable: true,
      excludeInUpdate: true
    },
    {
      header: t('availability'),
      name: 'availability',
      required: true,
      type: 'select',
      options: {
        data: [
          {
            availability: 1
          },
          {
            availability: 2
          },
          {
            availability: 7
          },
          {
            availability: 30
          },
          {
            availability: -1
          }
        ],
        name: 'availability',
        uniqueId: 'availability',
        requestField: 'availability'
      },
      isEditable: true,
      excludeInUpdate: true
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('movies')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'movieId'}
        tableId={'movie'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Movie;
