import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRole, prepareSelectUrl, productAvailableForPurchaseRender } from '../../../../utils';
import { FieldArray } from 'formik';
import { FaAddressCard } from 'react-icons/fa';
import ProductOfferCard from '../../../components/ProductOfferCard';
//prettier-ignore
const ProductOffer = () => {
  const urls = {
    list: config.PRODUCT_OFFER,
    create: config.PRODUCT_OFFER,
    update: config.PRODUCT_OFFER_WITH_ID,
    delete: config.PRODUCT_OFFER_WITH_ID
  };
  const platforms = ['APPLE', 'GOOGLE', 'HUAWEI'];
  const resource = 'OFFER';
  const { t } = useTranslation();

  const getColumns = () => {
    return [
      {
        header: t('id'),
        name: 'offerId',
        type: 'number',
        filterEditor: NumberFilter
      },
      {
        header: t('product'),
        name: 'product',
        type: 'select',
        isEditable: true,
        required: true,
        excludeInUpdate: true,
        options: {
          url: config.PRODUCTS_FOR_PURCHASE,
          selectUrlParser: prepareSelectUrl,
          name: 'name',
          uniqueId: 'productId',
          requestField: 'productId',
          render: productAvailableForPurchaseRender
        },
        render: ({ value }) => value && value.name + ' (' + value.productId + ')',
        defaultFlex: 1
      },
      {
        header: t('type'),
        name: 'productType',
        type: 'select',
        options: {
          url: config.PRODUCT_TYPE,
          name: 'productTypeName',
          uniqueId: 'productTypeName',
          requestField: 'productTypeName'
        },
        render: ({ value }) => value && value.productTypeName
      },
      {
        header: t('product.types'),
        name: 'offerType',
        type: 'select',
        isEditable: true,
        // enableUpdateOnlySubscription: true,
        options: {
          data: [
            {
              id: 1,
              name: 'REGULAR'
            },
            {
              id: 2,
              name: 'SPECIAL'
            }
          ],
          name: 'name',
          uniqueId: 'name',
          requestField: 'offerType'
        }
      },
      {
        header: t('nextOffer'),
        name: 'followUpOfferId',
        type: 'number',
        // enableUpdateOnlySubscription: true,
        isEditable: true
      },
      {
        header: t('country'),
        name: 'country',
        type: 'select',
        isEditable: true,
        required: true,
        excludeInUpdate: true,
        options: {
          url: config.COUNTRY,
          name: 'name',
          uniqueId: 'countryId',
          requestField: 'countryId'
        },
        render: ({ value }) => value && value.name,
        defaultFlex: 1
      },
      {
        header: t('currency'),
        name: 'currency',
        type: 'select',
        sortable: false,
        excludeInFilter: true,
        options: {
          url: config.CURRENCY,
          name: 'currencyName',
          uniqueId: 'currencyId',
          requestField: 'currencyId'
        },
        render: ( data ) => data?.data?.price?.currency?.currencyName
      },
      {
        header: t('price'),
        sideBarValue: ({ price }) => price && price.decimalPrice,
        name: 'decimalPrice',
        filterName: 'price.decimalPrice',
        id: 'price.decimalPrice',
        type: 'number',
        required: true,
        isEditable: true,
        render: ({ data }) => data && data.price && data.price.decimalPrice,
        filterEditor: NumberFilter
      },
      {
        header: t('trial.price'),
        sideBarValue: ({ price }) => price && price.trialDecimalPrice,
        name: 'trialDecimalPrice',
        filterName: 'price.trialDecimalPrice',
        id: 'price.trialDecimalPrice',
        type: 'number',
        defaultValue: null,
        isEditable: true,
        filterEditor: NumberFilter,
        render: ({ data }) => data && data.price && data.price.trialDecimalPrice
      },
      {
        header: t('valid.from'),
        name: 'validFrom',
        filterName: 'price.validFrom',
        id: 'price.validFrom',
        type: 'date',
        isEditable: true,
        required: true,
        render: ({ data }) => data && data.price && data.price.validFrom,
        filterEditor: DateFilter,
        dateFormat: 'YYYY-MM-DDTHH:mm:ss'
      },
      {
        header: t('is.free'),
        name: 'isFree',
        id: 'isFree.Insert',
        type: 'select',
        isEditable: true,
        excludeInTable: true,
        excludeInUpdate: true,
        required: true,
        options: {
          data: [
            {
              id: 1,
              name: 'Не бесплатно'
            },
            {
              id: 3,
              name: 'Бесплатно для новых абонентов'
            }
          ],
          name: 'name',
          uniqueId: 'id',
          requestField: 'isFree'
        }
      },
      {
        header: t('is.free'),
        id: 'isFree',
        type: 'boolean',
        filterEditor: BoolFilter,
        render: ({ data }) => {
          return <Switch disabled checked={data.isFree} />;
        }
      },
      {
        header: t('is.trial'),
        name: 'isTrial',
        type: 'boolean',
        isEditable: true,
        defaultValue: false,
        filterEditor: BoolFilter,
        render: ({ value }) => <Switch disabled checked={value} />
      },
      {
        header: t('marketplaces'),
        name: 'marketplaces',
        type: 'select',
        isEditable: true,
        asArray: true,
        options: {
          name: 'marketplace',
          uniqueId: ['marketplace', 'marketplaceId', 'marketplaceConnectionId'],
          requestField: 'marketplaces',
          multiple: true
        },
        render: ({ value }) => value && value.map((marketplace) => marketplace.marketplace).join(','),
        defaultFlex: 1,
        sideBarRender: (values, field, user, setFieldValue) => (
          <FieldArray name={field.name}>
            {({ remove, push }) => {
              return (
                <Grid container item spacing={3}>
                  {values[field.name]?.length > 0 &&
                  values[field.name].map((marketplace, index) => {
                    const disabled =
                      values[field.name || field.id][index].marketplaceConnectionId !== undefined;
                    const { admin, editor} = getRole(resource);
                    return (
                      <Grid container item xs={12} spacing={2} key={index}>
                        <Grid item xs={10} key={index}>
                          <Stack spacing={1}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Marketplace</InputLabel>

                              <Select
                                labelId="demo-simple-select-label"
                                disabled={disabled || !(admin || editor)}
                                value={values[field.name || field.id][index].marketplace}
                                onChange={(event) => {
                                  const clonedValues = [...values[field.name || field.id]];
                                  clonedValues[index].marketplace = event.target.value;
                                  setFieldValue(field.name || field.id, clonedValues);
                                }}>
                                {platforms.map((value) => (
                                  <MenuItem value={value} key={`${value}_${index}`}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              id={field.name || field.id}
                              label={'marketplaceId'}
                              type={'text'}
                              disabled={disabled || !(admin || editor)}
                              onChange={(event) => {
                                const clonedValues = [...values[field.name || field.id]];
                                clonedValues[index].marketplaceId = event.target.value;
                                setFieldValue(field.name || field.id, clonedValues);
                              }}
                              value={values[field.name || field.id][index].marketplaceId}
                              name={field.name || field.id}
                              fullWidth
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={2} key={`btn_${index}`}>
                          <Button
                            variant={'contained'}
                            className={'float-end'}
                            size="sm"
                            color={'error'}
                            fullWidth
                            onClick={() => {
                              remove(index);
                            }}>
                            {'X'}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      disabled={values[field.name || field.id]?.length >= platforms?.length}
                      fullWidth
                      onClick={() => push({ marketplace: '', marketplaceId: '' })}>
                      {t('add.marketplace')}
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          </FieldArray>
        )
      },
      {
        header: t('is.main'),
        name: 'isPrimary',
        type: 'boolean',
        isEditable: true,
        defaultValue: true,
        excludeInUpdate: true,
        filterEditor: BoolFilter,
        render: ({ value }) => <Switch disabled checked={value} />
      },
      {
        header: t('is.active'),
        name: 'isActive',
        type: 'boolean',
        isEditable: true,
        excludeInCreate: true,
        filterEditor: BoolFilter,
        render: ({ value }) => <Switch disabled checked={value} />
      },
      {
        header: t('date.added'),
        name: 'dateAdded',
        type: 'date',
        filterEditor: DateFilter,
        dateFormat: 'YYYY-MM-DDTHH:mm:ss'
      },
      {
        header: t('date.modified'),
        name: 'dateModified',
        type: 'date',
        filterEditor: DateFilter,
        dateFormat: 'YYYY-MM-DDTHH:mm:ss'
      }
    ];
  }


  const productOfferCardGenerator = (
    selectedRow,
    openCard,
    handleClickOpenCard,
    handleCloseCard
  ) => {
    return {
      toolTip: (
        <Tooltip title={t('detailed.product.offer')}>
          <IconButton onClick={handleClickOpenCard}>
            <FaAddressCard fontSize={20} />
          </IconButton>
        </Tooltip>
      ),
      card: <ProductOfferCard iamResource={resource} offer={selectedRow} open={openCard} handleClose={handleCloseCard} />
    };
  };

  return (
    <>
      <Header title={t('product.offers')} />
      <Toolbar iamResource={resource} detailedCardGenerator={productOfferCardGenerator} />
      <Table
        iamResource={resource}
        uniqueId={'offerId'}
        tableId={'offer'}
        columns={getColumns()}
        urls={urls}
        // disableDelete
        // isDeactivate
      />
      <NavBar />
    </>
  );
};

export default ProductOffer;
