import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getRequest } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions';

export default function AsyncSelect({ field, value, onChange, readOnly, required }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(field.options.data || []);
  const loading = open && options.length === 0;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (field.options.url) {
      let active = true;

      if (!loading) {
        return undefined;
      }
      getRequest(field.options.url, user.accessToken)
        .then((res) => {
          if (active) {
            if (res.data.data) setOptions(res.data.data);
            if (!res.data.data) setOptions(res.data);
          }
        })
        .catch((err) => {
          if (
            err.response.data &&
            (err.response.data.message === 'Signature verification failed' ||
              err.response.data.message === 'Signature has expired')
          ) {
            dispatch(setUser(null));
          }
        });

      return () => {
        active = false;
      };
    }
  }, [loading]);

  React.useEffect(() => {
    if (!open && !field.options.data) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={field.name}
      type={field.name}
      multiple={field.options.multiple}
      disabled={readOnly}
      getOptionDisabled={(option) => {
        return option.isActive !== undefined ? !option.isActive : false;
      }}
      open={open}
      value={
        typeof value === 'object'
          ? value
          : {
              [field.name]: value
            }
      }
      onChange={(e, v) => {
        onChange(field.name, v);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option[field.options.uniqueId] === value[field.options.uniqueId] ||
        option[field.options.uniqueId] === value
      }
      getOptionLabel={(option) => {
        return field.options.render !== undefined
          ? field.options.render(option, field)
          : option[field.options.name] || '';
      }}
      options={options}
      loading={loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[field.options.uniqueId]}>
            {field.options.render !== undefined
              ? field.options.render(option, field)
              : option[field.options.name]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={field.header}
          InputProps={{
            ...params.InputProps,
            form: {
              autocomplete: 'off'
            },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
