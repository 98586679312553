export default {
  ACCESS: 'ADMIN',
  ACCOUNT: 'ADMIN',
  AUTH: 'ADMIN',
  OFFER: 'ADMIN',
  ORDER: 'ADMIN',
  PAYMENT: 'ADMIN',
  PRODUCT: 'ADMIN',
  PROMO: 'ADMIN',
  PROMO_DISCOUNT: 'ADMIN',
  PROMO_DISTRIBUTOR: 'ADMIN',
  SYSTEM: 'ADMIN',
  // SALES: 'ADMIN',
  TRANSACTION: 'ADMIN'
};
