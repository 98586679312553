import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { FaFunnelDollar, FaGooglePlay, FaUserAlt } from 'react-icons/fa';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import config from '../../config';
import { deleteRequest, getRequest, getRole } from '../../utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './TabPanel';
import { useConfirmDialog } from 'react-mui-confirm';

const ProductOfferCard = ({ offer, open, handleClose, iamResource }) => {
  if (!offer || Object.keys(offer).length === 0) return;
  const [detailedOffer, setDetailedOffer] = useState();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const user = useSelector((state) => state.user);
  const confirm = useConfirmDialog();
  const role = useSelector((state) => state.permissions[iamResource]);
  const permissions = getRole(role);
  console.log('permissions', permissions);
  useEffect(() => {
    offer &&
      offer.offerId &&
      getRequest(config.PRODUCT_OFFER_WITH_ID(offer.offerId) + '/detailed', user.accessToken).then(
        (res) => {
          setDetailedOffer(res.data);
        }
      );
  }, [offer]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose}>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('offer.information')}
                icon={<FaUserAlt />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('marketplaces')}
                icon={<FaGooglePlay />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('special.price')}
                icon={<FaFunnelDollar />}
                iconPosition={'start'}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container>
              <Grid item md={5}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('product.name')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.product && detailedOffer.product.name}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('price')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.price && detailedOffer.price.decimalPrice}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('country')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer &&
                    detailedOffer.country &&
                    detailedOffer.country.name + ' (' + detailedOffer.country.code + ')'}
                </Typography>
              </Grid>

              <Grid item md={5}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('product.description')}:
                </Typography>
                <Typography component={'span'}>
                  {' '}
                  {detailedOffer && detailedOffer.product && detailedOffer.product.description}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('trial.price')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.price && detailedOffer.price.trialDecimalPrice}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('is.free')}:
                </Typography>
                <Typography component={'span'}>
                  <Checkbox
                    size="small"
                    disabled
                    checked={detailedOffer && !(detailedOffer.isFree === true)}
                  />
                </Typography>
              </Grid>

              <Grid item md={5}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('product.type')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer &&
                    detailedOffer.productType &&
                    detailedOffer.productType.productTypeName}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('valid.from')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.price && detailedOffer.price.validFrom}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('is.trial')}:
                </Typography>

                <Typography component={'span'}>
                  <Checkbox
                    size="small"
                    disabled
                    checked={detailedOffer && !(detailedOffer.isTrial === true)}
                  />
                </Typography>
              </Grid>

              <Grid item md={5}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('product.id')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.product && detailedOffer.product.productId}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('currency')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer &&
                    detailedOffer.price &&
                    detailedOffer.price.currency &&
                    detailedOffer.price.currency.currencyName +
                      ' (' +
                      detailedOffer.price.currency.currencyCode +
                      ')'}
                </Typography>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReactDataGrid
              idProperty={'marketplaceConnectionId'}
              columns={[
                {
                  header: t('id'),
                  name: 'marketplaceConnectionId',
                  type: 'number'
                },
                {
                  header: t('marketplace'),
                  name: 'marketplace',
                  type: 'select',
                  defaultFlex: 1
                },
                {
                  header: t('marketplace.id'),
                  name: 'marketplaceId',
                  type: 'string'
                }
              ]}
              enableColumnAutosize={false}
              dataSource={detailedOffer && detailedOffer.marketplaces}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container>
              <Grid item md={6}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('number.of.users.with.special.price')}:
                </Typography>
                <Typography component={'span'}>
                  {detailedOffer && detailedOffer.numberOfUsersWithSpecialPrice}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box textAlign="center">
                  <Button
                    variant={'contained'}
                    color={'error'}
                    itemID={offer.offerId}
                    onClick={() => {
                      confirm({
                        confirmButtonText: t('confirm'),
                        cancelButtonText: t('cancel'),
                        title:
                          t('deactivate.special.prices.for') +
                          ' ' +
                          detailedOffer.numberOfUsersWithSpecialPrice +
                          ' ' +
                          t('accounts').toLowerCase() +
                          ' ?',
                        onConfirm: () => {
                          deleteRequest(
                            config.SPECIAL_OFFER_PRICE_REMOVE_BY_OFFER_ID(offer.offerId),
                            user.accessToken
                          ).then(() => {
                            getRequest(
                              config.PRODUCT_OFFER_WITH_ID(offer.offerId) + '/detailed',
                              user.accessToken
                            ).then((res) => {
                              setDetailedOffer(res.data);
                            });
                          });
                        }
                      });
                    }}>
                    {t('delete')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductOfferCard;
