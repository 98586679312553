import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useTranslation } from 'react-i18next';

const PromoAttributes = () => {
  const urls = {
    list: config.PROMO_ATTRIBUTES,
    create: config.PROMO_ATTRIBUTES,
    update: config.PROMO_ATTRIBUTES_WITH_ID,
    delete: config.PROMO_ATTRIBUTES_WITH_ID
  };
  const resource = 'PRODUCT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'promoAttributeId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('allowed.device.type'),
      name: 'allowedDeviceType',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Allowed device type is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('allowed.device.subtype'),
      name: 'allowedDeviceSubType',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Allowed device sub type is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('allowed.account.status'),
      name: 'allowedAccountStatus',
      type: 'select',
      options: {
        data: [
          {
            allowedAccountStatus: 'ACTIVE'
          },
          {
            allowedAccountStatus: 'ON_TRIAL'
          },
          {
            allowedAccountStatus: 'INACTIVE'
          }
        ],
        name: 'allowedAccountStatus',
        uniqueId: 'allowedAccountStatus',
        requestField: 'allowedAccountStatus'
      },
      isEditable: true
    },
    {
      header: t('promo.code'),
      name: 'promoCode',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Promo code is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('promo.attributes')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'promoAttributeId'}
        tableId={'promoAttribute'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default PromoAttributes;
