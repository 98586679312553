import React from 'react';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import config from '../../../../config';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import * as Yup from 'yup';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Country = () => {
  const urls = {
    list: config.COUNTRY,
    create: config.COUNTRY,
    update: config.COUNTRY_WITH_ID,
    delete: config.COUNTRY_WITH_ID
  };
  const resource = 'SYSTEM';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'countryId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('name'),
      name: 'name',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Name is required'),
      isEditable: true,
      required: true,
      defaultFlex: 1
    },
    {
      header: t('code'),
      type: 'string',
      validationSchema: Yup.string().max(2).required('Code is required'),
      isEditable: true,
      required: true,
      name: 'code'
    },
    {
      header: t('currency'),
      type: 'select',
      isEditable: true,
      validationSchema: Yup.mixed().required('Currency is required'),
      name: 'defaultCurrency',
      required: true,
      options: {
        url: config.CURRENCY,
        name: 'currencyName',
        uniqueId: 'currencyId',
        requestField: 'defaultCurrencyId'
      },
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('phone.registration.allowed'),
      name: 'phoneRegistrationAllowed',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('email.registration.allowed'),
      name: 'emailRegistrationAllowed',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('countries')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'countryId'}
        tableId={'country'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Country;
