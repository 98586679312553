import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { prepareSelectUrl, productAvailableForPurchaseRender } from '../../../../utils';
import { CheckBox } from '@inovua/reactdatagrid-community/packages/RadioButtonGroup';

const Transaction = () => {
  const urls = {
    list: config.TRANSACTION,
    create: config.TRANSACTION
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const resource = 'TRANSACTION';
  const { t } = useTranslation();

  const customHeader = (
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab sx={{ minHeight: '20px' }} label={t('transaction.by.order')} />
      <Tab sx={{ minHeight: '20px' }} label={t('transaction.by.product')} />
    </Tabs>
  );

  const renderOrder = (data) => {
    return (
      data &&
      data.orderId +
        ' | ' +
        data.totalPrice +
        ', ' +
        (data.currency && data.currency.currencyCode) +
        ' | ' +
        (data.account && data.account.accountId)
    );
  };

  const orderSelectUrl = (field, value) => {
    const parsed = value.trim();

    const filterValues = [];

    if (/^\d+$/.test(value)) {
      filterValues.push({
        name: field.options.requestField,
        type: 'number',
        operator: 'eq',
        value: parsed
      });
    }

    return (
      field.options.url + '?filterValue=' + JSON.stringify(filterValues) + '&filterOperator=OR'
    );
  };

  const accountSelectUrl = (field, value) => {
    const parsed = value.trim();

    const filterValues = [
      {
        name: 'email',
        type: 'string',
        operator: 'contains',
        value: parsed
      },
      {
        name: 'phoneNumber',
        type: 'string',
        operator: 'contains',
        value: parsed
      },
      {
        name: 'uuid',
        type: 'string',
        operator: 'contains',
        value: parsed
      }
    ];
    if (/^\d+$/.test(value)) {
      filterValues.push({
        name: 'accountId',
        type: 'number',
        operator: 'eq',
        value: parsed
      });
    }

    return (
      field.options.url + '?filterValue=' + JSON.stringify(filterValues) + '&filterOperator=OR'
    );
  };

  const tableColumns = [
    {
      header: t('id'),
      name: 'transactionId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('type'),
      name: 'transactionType',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('is.successful'),
      name: 'isSuccess',
      type: 'bool',
      filterEditor: BoolFilter,
      render: ({ value }) => (
        <Box textAlign="center">
          <CheckBox disabled checked={value}></CheckBox>
        </Box>
      )
    },
    {
      header: t('transaction.date'),
      name: 'transactionTimestamp',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('price'),
      name: 'price',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'string',
      defaultFlex: 1,
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('order.id'),
      name: 'orderId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('association.id'),
      name: 'paymentAssociationId',
      type: 'number',
      filterEditor: NumberFilter
    }
  ];

  const creationByOrderColumns = [
    {
      header: t('order.id'),
      name: 'orderId',
      type: 'select',
      options: {
        url: config.ORDER,
        selectUrlParser: orderSelectUrl,
        name: 'name',
        uniqueId: 'orderId',
        requestField: 'orderId',
        render: renderOrder
      }
    },
    {
      header: t('price'),
      name: 'price',
      type: 'number',
      required: true
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'select',
      options: {
        url: config.CURRENCY,
        name: 'currencyName',
        uniqueId: 'currencyId',
        requestField: 'currencyId'
      },
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('external.transaction.id'),
      name: 'externalTransactionId',
      type: 'string',
      required: true
    }
  ];

  const creationByProductColumns = [
    {
      header: t('product.id'),
      name: 'productId',
      type: 'select',
      options: {
        url: config.SUBSCRIPTION,
        selectUrlParser: prepareSelectUrl,
        name: 'name',
        uniqueId: 'productId',
        requestField: 'productId',
        render: productAvailableForPurchaseRender
      },
      required: true
    },
    {
      header: t('account.id'),
      name: 'accountId',
      type: 'select',
      options: {
        url: config.ACCOUNT,
        selectUrlParser: accountSelectUrl,
        name: 'accountId',
        uniqueId: 'accountId',
        requestField: 'accountId',
        render: (data) =>
          data && data.accountId + ' (' + (data.email || data.phoneNumber || data.uuid) + ')'
      }
    },
    {
      header: t('price'),
      name: 'price',
      type: 'number',
      required: true
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'select',
      options: {
        url: config.CURRENCY,
        name: 'currencyName',
        uniqueId: 'currencyId',
        requestField: 'currencyId'
      },
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('external.transaction.id'),
      name: 'externalTransactionId',
      type: 'string',
      required: true
    }
  ];

  const creationColumns = value ? creationByProductColumns : creationByOrderColumns;

  return (
    <>
      <Header title={t('transactions')} />
      <Toolbar iamResource={resource} />
      <Table
        disableUpdate
        disableDelete
        iamResource={resource}
        customSidebarHeader={customHeader}
        uniqueId={'transactionId'}
        tableId={'transactions'}
        columns={tableColumns.concat(
          creationColumns.map((column) => {
            return { ...column, isEditable: true, excludeInTable: true };
          })
        )}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Transaction;
