import React, { useState } from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { IoIosOpen } from 'react-icons/io';
import OneToManyDialog from '../../../components/OneToManyDialog';
import ProductOfferFromStructure from '../../../components/ProductOfferFromStructure';

const Order = () => {
  const urls = {
    list: config.ORDER,
    create: config.ORDER,
    update: config.ORDER_WITH_ID,
    delete: config.ORDER_WITH_ID
  };
  const resource = 'ORDER';
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);

  const columns = [
    {
      header: t('id'),
      name: 'orderId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('account.id'),
      name: 'account.accountId',
      type: 'number',
      filterEditor: NumberFilter,
      render: ({ data }) =>
        data &&
        data.account &&
        data.account.accountId +
          ' (' +
          (data.account.email || data.account.phoneNumber || data.account.uuid) +
          ')'
    },
    {
      header: t('currency'),
      name: 'currency',
      type: 'select',
      render: ({ value }) => value && value.currencyName
    },
    {
      header: t('type'),
      name: 'orderType',
      type: 'string',
      isEditable: true
    },
    {
      header: t('status'),
      name: 'orderStatus',
      type: 'string',
      isEditable: true
    },
    {
      header: t('base.price'),
      name: 'totalBasePrice',
      type: 'string',
      isEditable: true
    },
    {
      header: t('products'),
      name: 'items',
      type: 'select',
      render: ({ value }) => {
        return value.length === 1 ? (
          ProductOfferFromStructure(value[0])
        ) : value.length > 1 ? (
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => {
              setProducts(value);
            }}>
            <IoIosOpen />
          </IconButton>
        ) : undefined;
      }
    },
    {
      header: t('price'),
      type: 'string',
      isEditable: true,
      name: 'totalPrice'
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter
    },
    {
      header: t('date.payed'),
      name: 'datePayed',
      type: 'date',
      filterEditor: DateFilter
    },
    {
      header: t('devices'),
      name: 'deviceTypeId',
      type: 'string',
      isEditable: true,
      render: ({ value }) =>
        value &&
        (() => {
          for (let key in config.DEVICE_TYPE) {
            if (value === +key) {
              return config.DEVICE_TYPE[key];
            }
          }
        })()
    }
  ];

  return (
    <>
      <Header title={t('orders')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'orderId'}
        tableId={'order'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
      <OneToManyDialog
        columns={[
          {
            header: t('id'),
            name: 'offer.product.productId',
            render: ({ data }) =>
              data && data.offer && data.offer.product && data.offer.product.productId,
            defaultFlex: 1
          },
          {
            header: t('name'),
            name: 'offer.product.name',
            render: ({ data }) =>
              data && data.offer && data.offer.product && data.offer.product.name,
            defaultFlex: 1
          },
          {
            header: t('description'),
            name: 'offer.product.description',
            render: ({ data }) =>
              data && data.offer && data.offer.product && data.offer.product.description,

            defaultFlex: 1
          },
          {
            header: t('type'),
            name: 'offer.productType',
            render: ({ data }) =>
              data &&
              data.offer &&
              data.offer.productType &&
              data.offer.productType.productTypeName,

            defaultFlex: 1
          },
          {
            header: t('price'),
            name: 'finalCost',
            render: ({ data }) => data && data.finalItemPrice,
            defaultFlex: 1
          },
          {
            header: t('price'),
            name: 'offer.',
            render: ({ data }) => data && data.finalItemPrice,
            defaultFlex: 1
          }
        ]}
        data={products}
        open={products.length > 0}
        handleClose={() => {
          setProducts([]);
        }}
      />
    </>
  );
};

export default Order;
