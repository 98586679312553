import React, { useEffect } from 'react';
import { FaList } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenedTables } from '../../../actions';
import { menu as _menu } from '../../../config';
import { Box, Tab, Tabs } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';

function LinkTab(props) {
  const navigate = useNavigate();
  return (
    <Tab
      component="button"
      onClick={() => {
        navigate(props.to);
      }}
      label={
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <span
            style={{
              color: props.active ? '#20bec6' : '#000',
              textTransform: 'initial',
              marginBottom: '4px'
            }}>
            {props.label}
          </span>
          <CloseOutlined
            onClick={(event) => {
              event.stopPropagation();
              props.onClose();
            }}
            style={{ marginLeft: '20px' }}
          />
        </Box>
      }
    />
  );
}

const NavBar = () => {
  const openedTables = useSelector((state) => state.openedTables);
  const permissions = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const menu = _menu(permissions);
  const item = menu.find((item) => item.href === location.pathname);
  const value = openedTables.indexOf(item && item.name);
  useEffect(() => {
    const item = menu.find((item) => item.href === location.pathname);
    if (item && !openedTables.includes(item.name)) {
      dispatch(setOpenedTables([...openedTables, item.name]));
    }
  }, [location]);
  return (
    <div className="nav-bar">
      <Dropdown>
        <Dropdown.Toggle as={'button'} className="icon">
          <FaList />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {menu.map((item) => {
            // console.log('NavBAr item', item);
            return (
              <Dropdown.Item
                key={item.name}
                className={'d-flex align-items-center'}
                onClick={() => {
                  if (!openedTables.includes(item.name)) {
                    dispatch(setOpenedTables([...openedTables, item.name]));
                  }
                  navigate(item.href);
                }}>
                <span>{t(item.name)}</span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <Tabs value={value} variant="scrollable" scrollButtons="auto">
        {openedTables.map((name) => {
          let item = menu.find((t) => t.name === name);
          return (
            <LinkTab
              key={item.name}
              to={item.href}
              active={item.href === location.pathname}
              label={t(item.name)}
              onClose={() => {
                dispatch(setOpenedTables(openedTables.filter((table) => table !== item.name)));
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default NavBar;
