import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { IoInformationCircleSharp } from 'react-icons/io5';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils';

const AccountHistoryPopover = ({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns = [
    {
      name: 'accountPurchaseId',
      header: t('id'),
      defaultFlex: 1
    },
    {
      name: 'linkedAccountPurchaseId',
      header: t('related.purchase.id'),
      group: 'changes',
      defaultFlex: 1
    },
    {
      name: 'event',
      header: t('action'),
      group: 'changes',
      defaultFlex: 1
    },
    {
      name: 'process',
      group: 'changes',
      defaultFlex: 1
    },
    {
      name: 'dateEvent',
      group: 'changes',
      defaultFlex: 2,
      render: ({ data }) => formatDate(data.dateEvent)
    },
    {
      name: 'valid_to',
      group: 'valid_to',
      header: t('old'),
      defaultFlex: 2,
      render: ({ data }) => formatDate(data.change?.valid_to?.old_value)
    },
    {
      name: 'valid_to',
      group: 'valid_to',
      header: t('new'),
      defaultFlex: 2,
      render: ({ data }) => formatDate(data.change?.valid_to?.new_value)
    },
    {
      name: 'is_active',
      header: t('old'),
      group: 'is_active',
      defaultFlex: 1,
      render: ({ data }) => data.change?.is_active?.new_value.toString()
    },
    {
      name: 'is_active',
      header: t('new'),
      group: 'is_active',
      defaultFlex: 1,
      render: ({ data }) => data.change?.is_active?.old_value.toString()
    },
    {
      name: 'expiration_date',
      group: 'expiration_date',
      header: t('old'),
      defaultFlex: 2,
      render: ({ data }) => formatDate(data.change?.expiration_date?.old_value)
    },
    {
      name: 'expiration_date',
      group: 'expiration_date',
      header: t('new'),
      defaultFlex: 2,
      render: ({ data }) => formatDate(data.change?.expiration_date?.new_value)
    }
  ];

  const groups = [
    { name: 'changes', header: t('history.of.changes') },
    { name: 'is_active', group: 'changes', header: t('is.active') },
    { name: 'expiration_date', group: 'changes', header: t('grace.expiration.date') },
    { name: 'valid_to', group: 'changes', header: t('purchase.valid.to') }
  ];

  const gridStyle = { minHeight: '22vh' };

  return (
    <div>
      <IoInformationCircleSharp
        style={{ cursor: 'pointer' }}
        size={30}
        aria-describedby={id}
        onClick={handleClick}
      />
      <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={handleClose}>
        <DialogContent>
          <ReactDataGrid
            idProperty={'accountHistoryPopoverId'}
            enableColumnAutosize={true}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            columns={columns}
            groups={groups}
            dataSource={value.changes}
            style={gridStyle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountHistoryPopover;
