import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { formatDate } from '../../utils';

const DetailPopover = ({ value }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns = [
    {
      name: 'accountPurchaseId',
      header: t('id'),
      defaultFlex: 2
    },
    {
      name: 'name',
      header: t('name'),
      defaultFlex: 1,
      render: ({ data }) => data?.offer?.product?.name
    },
    {
      name: 'validFrom',
      header: t('valid.from'),
      defaultFlex: 2,
      render: ({ value }) => formatDate(value)
    },
    {
      name: 'validTo',
      header: t('purchase.valid.to'),
      defaultFlex: 2,
      render: ({ value }) => formatDate(value)
    },
    {
      name: 'expirationDate',
      header: t('grace.expiration.date'),
      defaultFlex: 2,
      render: ({ value }) => formatDate(value)
    },
    {
      name: 'marketplaceOrderId',
      header: t('marketplace.order.id'),
      defaultFlex: 2
    },
    {
      name: 'orderId',
      header: t('order.id'),
      defaultFlex: 2
    },
    {
      name: 'isTrial',
      header: t('is.trial'),
      defaultFlex: 2,
      render: ({ value }) => (value ? 'Yes' : '-')
    },
    {
      name: 'isActive',
      header: t('is.active'),
      defaultFlex: 2,
      render: ({ value }) => (value ? 'Yes' : '-')
    }
  ];
  const gridStyle = { minHeight: '22vh' };
  return (
    <div>
      <IoInformationCircleSharp
        style={{ cursor: 'pointer' }}
        size={30}
        aria-describedby={id}
        onClick={handleClick}
      />
      <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={handleClose}>
        <DialogContent>
          <ReactDataGrid
            idProperty={'accountHistoryPopoverId'}
            enableColumnAutosize={true}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            columns={columns}
            dataSource={[value]}
            style={gridStyle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DetailPopover;
