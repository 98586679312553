const setOpenedTables = (openedTables) => {
  return {
    type: 'SET_OPENED_TABLES',
    payload: openedTables
  };
};

const setSelectedRow = (selectedRow) => {
  return {
    type: 'SET_SELECTED_ROW',
    payload: selectedRow
  };
};

const setUser = (user) => {
  return {
    type: 'SET_USER',
    payload: user
  };
};

const setPermissions = (permission) => {
  return {
    type: 'SET_PERMISSION',
    payload: permission
  };
};

const setDefault = () => {
  return {
    type: 'SET_DEFAULT'
  };
};

export { setOpenedTables, setSelectedRow, setUser, setPermissions, setDefault };
