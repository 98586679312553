import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRow } from '../../../actions';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import { MdHistory } from 'react-icons/md';
import HistoryCard from '../HistoryCard';
import { useTranslation } from 'react-i18next';
import { getRole } from '../../../utils';

const Toolbar = ({ detailedCardGenerator, disableCreating, iamResource, customCardGenerator }) => {
  const selectedRow = useSelector((state) => state.selectedRow);
  const role = useSelector((state) => state.permissions[iamResource]);
  const permissions = getRole(role);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  // console.log('customCardGenerator', customCardGenerator);
  // console.log('detailedCardGenerator', detailedCardGenerator);
  const [openCard, setOpenCard] = React.useState(false);
  const [openCustomCard, setOpenCustomCard] = React.useState(false);
  const [openHistoryCard, setOpenHistoryCard] = React.useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };
  const handleClickOpenCard = () => {
    if (selectedRow && selectedRow !== {}) {
      setOpenCard(true);
    } else {
      handleClickAlert();
    }
  };

  const handleCloseCard = () => {
    setOpenCard(false);
  };

  const handleClickOpenHistoryCard = () => {
    setOpenHistoryCard(true);
  };

  const handleCloseHistoryCard = () => {
    setOpenHistoryCard(false);
  };

  const handleClickOpenCustomCard = () => {
    if (selectedRow && selectedRow !== {}) {
      setOpenCustomCard(true);
    } else {
      handleClickAlert();
    }
  };

  const handleCloseCustomCard = () => {
    setOpenCustomCard(false);
  };

  const detailedCard = detailedCardGenerator
    ? detailedCardGenerator(selectedRow, openCard, handleClickOpenCard, handleCloseCard)
    : undefined;

  const customCard = customCardGenerator
    ? customCardGenerator(
        selectedRow,
        openCustomCard,
        handleClickOpenCustomCard,
        handleCloseCustomCard
      )
    : undefined;

  return (
    <div className={'toolbar d-flex flex-row'}>
      <div className={'d-flex align-items-center'}>
        <div className={'ms-5'} />
        {permissions.creator && !disableCreating && (
          <Tooltip title={t('add.new')}>
            <IconButton onClick={() => dispatch(setSelectedRow({}))}>
              <FaPlus fontSize={20} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('history.of.changes')}>
          <IconButton onClick={handleClickOpenHistoryCard}>
            <MdHistory fontSize={24} />
          </IconButton>
        </Tooltip>
        {detailedCard && detailedCard.toolTip}
        {permissions.editor && customCard && customCard.toolTip}
      </div>
      {detailedCard && detailedCard.card}
      {permissions.editor && customCard && customCard.card}
      <HistoryCard open={openHistoryCard} handleClose={handleCloseHistoryCard} color="primary" />
      <Snackbar
        sx={{ bottom: '50px !important' }}
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
          Please select account!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toolbar;
