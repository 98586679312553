import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import IconButton from '@mui/material/IconButton';
import { FaCreditCard, FaGoogle, FaUserAlt } from 'react-icons/fa';
import { TbDiscount2, TbShoppingCartDiscount } from 'react-icons/tb';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import config from '../../config';
import moment from 'moment';
import { deleteRequest, formatDate, getRequest, postRequest } from '../../utils';
import { useSelector } from 'react-redux';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from 'react-mui-confirm';
import { TabPanel } from './TabPanel';
import DetailPopover from './DetailPopover';
import AccountHistoryPopover from './AccountHistoryPopover';

const AccountCard = ({ account, open, handleClose }) => {
  if (!account || Object.keys(account).length === 0) return;
  const [detailedAccount, setDetailedAccount] = useState();
  const [accountDevices, setAccountDevices] = useState([]);
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const user = useSelector((state) => state.user);
  const confirm = useConfirmDialog();

  const _handleClose = () => {
    setValue(0);
    setAccountDevices([]);
    handleClose();
  };

  useEffect(() => {
    account &&
      account.accountId &&
      getRequest(config.ACCOUNT_WITH_ID(account.accountId) + '/detailed', user.accessToken).then(
        (res) => {
          const { purchases } = res.data;
          const subscription = [];
          const movie = [];
          purchases.map((item) => {
            const { productType } = item.offer.productType;
            if (productType === 'subscription') subscription.push(item);
            if (productType === 'movie') {
              movie.push(item);
            }
          });
          setDetailedAccount({ ...res.data, purchases: { subscription, movie } });
        }
      );
  }, [account, open]);

  const getUserDeviceList = () => {
    postRequest(
      config.USER_DEVICE_LIST,
      {
        user_id: account.accountId,
        billing_id: 2
      },
      user.accessToken
    ).then((res) => {
      const { devices } = res.data;
      if (devices) {
        setAccountDevices(devices);
      } else {
        setAccountDevices([]);
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };
  const gridStyle = { minHeight: isFullscreen ? '75vh' : '22vh' };
  return (
    <Dialog
      fullScreen={isFullscreen}
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={_handleClose}>
      <DialogActions>
        <Button onClick={changeFullscreen} hide>
          <IconButton>
            {!isFullscreen ? (
              <OpenInFullIcon color="primary" />
            ) : (
              <CloseFullscreenIcon color="primary" />
            )}
          </IconButton>
        </Button>
      </DialogActions>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example">
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('account.information')}
                icon={<FaUserAlt />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('payment.cards')}
                icon={<FaCreditCard />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('social.networks')}
                icon={<FaGoogle />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('subscriptions')}
                icon={<TbShoppingCartDiscount size={18} />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('premieres')}
                icon={<TbShoppingCartDiscount size={18} />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('promo.codes')}
                icon={<TbDiscount2 size={18} />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('devices')}
                icon={<TbShoppingCartDiscount size={18} />}
                iconPosition={'start'}
                onClick={() => getUserDeviceList()}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('first.name')}:
                </Typography>
                <Typography component={'span'}>{account && account.firstName}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('middle.name')}:
                </Typography>
                <Typography component={'span'}>{account && account.middleName}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('last.name')}:
                </Typography>
                <Typography component={'span'}>{account && account.lastName}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('nickname')}:
                </Typography>
                <Typography component={'span'}>{account && account.nickname}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('email')}:
                </Typography>
                <Typography component={'span'}>{account && account.email}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('phone.number')}:
                </Typography>
                <Typography component={'span'}>{account && account.phoneNumber}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('registration.country')}:
                </Typography>
                <Typography component={'span'}>
                  {account && account.registrationCountry && account.registrationCountry.name}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('registration.method')}:
                </Typography>
                <Typography component={'span'}>{account && account.registrationMethod}</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('residence.country')}:
                </Typography>
                <Typography component={'span'}>
                  {account && account.residenceCountry && account.residenceCountry.name}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography fontWeight={600} mr={2} component={'span'}>
                  {t('date.of.birth')}:
                </Typography>
                <Typography component={'span'}>
                  {account && moment(account.bateOfBirth).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'cardId'}
              columns={[
                {
                  header: t('id'),
                  name: 'cardId',
                  defaultFlex: 1
                },
                {
                  header: t('alias'),
                  name: 'cardAlias',
                  defaultFlex: 1
                },
                {
                  header: t('card.number'),
                  name: 'cardNumber',
                  defaultFlex: 1
                },
                {
                  header: t('expiration.date'),
                  name: 'expireDate',
                  defaultFlex: 1
                }
              ]}
              enableColumnAutosize={true}
              dataSource={detailedAccount && detailedAccount.paymentCards}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'socialNetworkRegistrationId'}
              columns={[
                {
                  header: t('id'),
                  name: 'socialNetworkRegistrationId',
                  defaultFlex: 1
                },
                {
                  header: t('name'),
                  name: 'authProvider',
                  defaultFlex: 1
                },
                {
                  header: t('action'),
                  name: 'Action',
                  // defaultFlex: 1,
                  render: (value) => (
                    <Button
                      variant={'contained'}
                      color={'error'}
                      itemID={value.data.socialNetworkRegistrationId}
                      className={'position-absolute top-0 start-50 translate-middle-x'}
                      onClick={() => {
                        confirm({
                          confirmButtonText: t('confirm'),
                          cancelButtonText: t('cancel'),
                          title:
                            t('unlink.account') +
                            ' ' +
                            (account.email || account.phoneNumber || account.accountId) +
                            ' ' +
                            t('from') +
                            ' ' +
                            value.data.socialNetworkName +
                            '?',
                          onConfirm: () => {
                            deleteRequest(
                              config.ACCOUNT_SOCIAL_NETWORK_WITH_ID(
                                value.data.socialNetworkRegistrationId
                              ),
                              user.accessToken
                            ).then(() => {
                              getRequest(
                                config.ACCOUNT_WITH_ID(account.accountId) + '/detailed',
                                user.accessToken
                              ).then((res) => {
                                setDetailedAccount(res.data);
                              });
                            });
                          }
                        });
                      }}>
                      {t('unlink')}
                    </Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={detailedAccount && detailedAccount.socialNetworks}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'accountPurchaseSubscriptionId'}
              columns={[
                {
                  header: t('order.id'),
                  name: 'orderId',
                  defaultFlex: 1,
                  filterEditor: NumberFilter,
                  render: ({ data }) => data.orderId || data.marketplaceOrderId
                },
                {
                  header: t('marketplace'),
                  name: 'marketplaceOrderId',
                  defaultFlex: 1,
                  filterEditor: NumberFilter,
                  render: ({ data }) => <Switch disabled checked={data.marketplaceOrderId} />
                },
                {
                  header: t('valid.from'),
                  name: 'validFrom',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('purchase.valid.to'),
                  name: 'validTo',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('grace.expiration.date'),
                  name: 'expirationDate',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('product'),
                  name: 'offer',
                  defaultFlex: 1,
                  render: ({ value }) => value && value.product && value.product.name
                },
                {
                  header: t('country'),
                  id: 'country',
                  defaultFlex: 1,
                  render: ({ data: { offer } }) => offer && offer.country && offer.country.name
                },
                {
                  name: t('more.info'),
                  defaultVisible: true,
                  defaultWidth: 120,
                  render: (prop) => <DetailPopover value={prop?.data} />
                },
                {
                  header: t('history.of.changes'),
                  name: 'changes',
                  defaultVisible: true,
                  defaultWidth: 120,
                  render: (prop) => <AccountHistoryPopover value={prop?.data} />
                }
              ]}
              enableColumnAutosize={true}
              dataSource={detailedAccount && detailedAccount.purchases.subscription}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'accountPurchasePremiereId'}
              columns={[
                {
                  header: t('order.id'),
                  name: 'orderId',
                  defaultFlex: 1,
                  filterEditor: NumberFilter,
                  render: ({ data }) => data.orderId || data.marketplaceOrderId
                },
                {
                  header: t('marketplace'),
                  name: 'marketplaceOrderId',
                  defaultFlex: 1,
                  filterEditor: NumberFilter,
                  render: ({ data }) => <Switch disabled checked={data.marketplaceOrderId} />
                },
                {
                  header: t('valid.from'),
                  name: 'validFrom',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('purchase.valid.to'),
                  name: 'validTo',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('grace.expiration.date'),
                  name: 'expirationDate',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('product'),
                  name: 'offer',
                  defaultFlex: 1,
                  render: ({ value }) => value && value.product && value.product.name
                },
                {
                  header: t('country'),
                  id: 'country',
                  defaultFlex: 1,
                  render: (v) => {
                    return (
                      v.data && v.data.offer && v.data.offer.country && v.data.offer.country.name
                    );
                  }
                },
                {
                  name: t('more.info'),
                  defaultVisible: true,
                  defaultWidth: 120,
                  render: (prop) => <DetailPopover value={prop?.data} />
                },
                {
                  header: t('history.of.changes'),
                  name: 'changes',
                  defaultVisible: true,
                  defaultWidth: 120,
                  render: (prop) => <AccountHistoryPopover value={prop?.data} />
                }
              ]}
              enableColumnAutosize={true}
              dataSource={detailedAccount && detailedAccount.purchases.movie}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'accountPromoId'}
              columns={[
                {
                  header: t('id'),
                  name: 'accountPromoId',
                  defaultFlex: 1
                },
                {
                  header: t('promo.code'),
                  name: 'promoCode',
                  defaultFlex: 1
                },
                {
                  header: t('date.activated'),
                  name: 'dateActivated',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                }
              ]}
              enableColumnAutosize={true}
              dataSource={detailedAccount && detailedAccount.promos}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <ReactDataGrid
              editable={true}
              style={gridStyle}
              idProperty={'devices'}
              columns={[
                {
                  header: t('mac'),
                  name: 'mac',
                  defaultFlex: 1
                },
                {
                  header: t('uuid'),
                  name: 'uuid',
                  defaultFlex: 1
                },
                {
                  header: t('refreshToken'),
                  name: 'refresh_token',
                  defaultFlex: 1
                },
                {
                  header: t('model'),
                  name: 'model',
                  defaultFlex: 1
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('dateModify'),
                  name: 'date_last_modified ',
                  defaultFlex: 1,
                  render: ({ value }) => formatDate(value)
                },
                {
                  header: t('deviceType'),
                  // name: 'device_type ',
                  render: (data) => {
                    return data?.data?.device_type;
                  },
                  defaultFlex: 1
                },
                {
                  header: t('deviceSubtype'),
                  name: 'device_sub_type',
                  defaultFlex: 1
                },
                {
                  header: t('applicationType'),
                  name: 'application_type',
                  defaultFlex: 1
                },
                {
                  header: t('ipAddress'),
                  name: 'ip_address',
                  defaultFlex: 1
                }
              ]}
              enableColumnAutosize={true}
              dataSource={accountDevices && accountDevices}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountCard;
