import React, { useState } from 'react';
import LogoImage from '../../../assets/images/logo.png';
import SettingsModal from '../SettingsModal/SettingsModal';
import {
  Avatar,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FiSettings } from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setDefault } from '../../../actions';
import SearchBar from '../SearchBar/SearchBar';
import { deleteRequest } from '../../../utils';
import config from '../../../config';
import { useTranslation } from 'react-i18next';

const Header = ({ title }) => {
  const [settingsModalShow, setSettingsModalShow] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    setSettingsModalShow(true);
  };

  const handleLogout = () => {
    deleteRequest(config.ACCESS_REVOKE, user.accessToken);
    deleteRequest(config.REFRESH_REVOKE, user.refreshToken);
    dispatch(setDefault());
  };
  return (
    <Grid container className={'header'} alignItems={'center'} justifyContent={'space-between'}>
      <Grid item alignItems={'center'}>
        <Grid container alignItems={'center'}>
          <Grid item ml={2} mr={1}>
            <img src={LogoImage} />
          </Grid>
          <Grid item alignItems={'center'}>
            <Typography variant={'h5'}>{title}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <SettingsModal show={settingsModalShow} onHide={() => window.location.reload()} />
      <Grid item justifyContent={'end'}>
        <Grid container alignItems={'center'}>
          <SearchBar />
          <Avatar
            sx={{ bgcolor: theme.palette.primary.main, mr: 2, cursor: 'pointer' }}
            onClick={(event) => handleClick(event)}>
            OP
          </Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            <MenuItem
              onClick={() => {
                handleSettings();
                handleClose();
              }}>
              <ListItemIcon>
                <FiSettings />
              </ListItemIcon>
              <ListItemText sx={{ ml: -1 }}>{t('settings')}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}>
              <ListItemIcon>
                <BiLogOut />
              </ListItemIcon>
              <ListItemText sx={{ ml: -1 }}>{t('logout')}</ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
