import reducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';

const KEY = 'redux';
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}

const store = configureStore({
  reducer: reducer,
  // here we restore the previously persisted state
  preloadedState: loadState()
});

const { dispatch } = store;

export { store, dispatch };
export default store;
