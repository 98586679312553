import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import { useTranslation } from 'react-i18next';

const ProductType = () => {
  const urls = {
    list: config.PRODUCT_TYPE,
    create: config.PRODUCT_TYPE,
    update: config.PRODUCT_TYPE_WITH_ID,
    delete: config.PRODUCT_TYPE_WITH_ID
  };
  const resource = 'PRODUCT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'productTypeId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('name'),
      name: 'productTypeName',
      type: 'string',
      required: true,
      validationSchema: Yup.string().max(255).required('Name is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('description'),
      type: 'string',
      required: true,
      validationSchema: Yup.string().max(255).required('Description is required'),
      isEditable: true,
      name: 'productTypeDescription'
    },
    {
      header: t('type'),
      name: 'productType',
      required: true,
      type: 'select',
      options: {
        data: [
          {
            productType: 'subscription'
          },
          {
            productType: 'movie'
          },
          {
            productType: 'content_owner'
          },
          {
            productType: 'tv_package'
          },
          {
            productType: 'product_set'
          },
          {
            productType: 'other'
          }
        ],
        name: 'productType',
        uniqueId: 'productType',
        requestField: 'productType'
      },
      isEditable: true
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('product.types')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'productTypeId'}
        tableId={'productType'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default ProductType;
