import React, { useState } from 'react';
import config from '../../../../config';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import { IconButton, Switch } from '@mui/material';
import { IoIosOpen } from 'react-icons/io';
import OneToManyDialog from '../../../components/OneToManyDialog';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { useTranslation } from 'react-i18next';
import { prepareSelectUrl, productAvailableForPurchaseRender } from '../../../../utils';

const Subscription = () => {
  const urls = {
    list: config.SUBSCRIPTION,
    create: config.SUBSCRIPTION,
    update: config.SUBSCRIPTION_WITH_ID,
    delete: config.SUBSCRIPTION_WITH_ID
  };
  const [products, setProducts] = useState([]);
  const resource = 'PRODUCT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'subscriptionId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('name'),
      name: 'name',
      type: 'string',
      required: true,
      validationSchema: Yup.string().max(255).required('Name is required'),
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('description'),
      type: 'string',
      required: true,
      validationSchema: Yup.string().max(255).required('Description is required'),
      isEditable: true,
      name: 'description'
    },
    {
      header: t('external.id'),
      name: 'externalId',
      type: 'number',
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('product.id'),
      id: 'productId',
      type: 'number',
      render: ({ data }) => data && data.productId,
      filterEditor: NumberFilter
    },
    {
      header: t('type'),
      name: 'productType',
      type: 'select',
      render: ({ value }) => value.productTypeName,
      isEditable: false
    },
    {
      header: t('subscription.group'),
      name: 'subscriptionGroup',
      type: 'select',
      render: ({ value }) => value && value.subscriptionGroupName,
      isEditable: true,
      options: {
        url: config.SUBSCRIPTION_GROUP,
        name: 'subscriptionGroupName',
        uniqueId: 'subscriptionGroupId',
        render: (data) =>
          data.subscriptionGroupName &&
          `${data.subscriptionGroupName} | ${data.subscriptionGroupId}`,
        requestField: 'subscriptionGroupId'
      }
    },
    {
      header: t('products'),
      name: 'products',
      type: 'select',
      required: true,
      sortable: false,
      excludeInFilter: true,
      options: {
        url: config.PRODUCT,
        selectUrlParser: prepareSelectUrl,
        name: 'name',
        uniqueId: 'productId',
        requestField: 'productIds',
        render: productAvailableForPurchaseRender,
        multiple: true
      },
      render: ({ value }) => (
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={() => {
            setProducts(value);
          }}>
          <IoIosOpen />
        </IconButton>
      ),
      isEditable: true
    },
    {
      header: t('valid.days'),
      name: 'validDays',
      type: 'number',
      isEditable: true,
      required: true,
      excludeInUpdate: true,
      filterEditor: NumberFilter
    },
    {
      header: t('grace.days'),
      name: 'graceDays',
      defaultValue: null,
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('trial.days'),
      name: 'trialDays',
      type: 'number',
      isEditable: true,
      excludeInUpdate: true,
      filterEditor: NumberFilter
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('subscriptions')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'subscriptionId'}
        tableId={'subscription'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
      <OneToManyDialog
        columns={[
          {
            header: t('id'),
            name: 'productId',
            defaultFlex: 1
          },
          {
            header: t('name'),
            name: 'name',
            defaultFlex: 1
          },
          {
            header: t('description'),
            name: 'description',
            defaultFlex: 1
          },
          {
            header: t('type'),
            name: 'productType',
            render: ({ value }) => value.productTypeName,
            defaultFlex: 1
          },
          {
            header: t('date.added'),
            name: 'dateAdded',
            defaultFlex: 1
          },
          {
            header: t('date.modified'),
            name: 'dateModified',
            defaultFlex: 1
          }
        ]}
        data={products}
        open={products.length > 0}
        handleClose={() => {
          setProducts([]);
        }}
      />
    </>
  );
};

export default Subscription;
