import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prepareSelectUrlForProductOfferOptions } from '../../../../utils';
import ProductOfferFromStructure from '../../../components/ProductOfferFromStructure';

const Marketplace = () => {
  const urls = {
    list: config.MARKETPLACE_CONNECTION,
    create: config.MARKETPLACE_CONNECTION,
    update: config.MARKETPLACE_CONNECTION_WITH_ID,
    delete: config.MARKETPLACE_CONNECTION_WITH_ID
  };
  const resource = 'ORDER';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'marketplaceConnectionId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('marketplace'),
      name: 'marketplace',
      type: 'select',
      defaultFlex: 1,
      required: true,
      options: {
        data: [
          {
            marketplace: 'APPLE'
          },
          {
            marketplace: 'GOOGLE'
          },
          {
            marketplace: 'HUAWEI'
          }
        ],
        name: 'marketplace',
        uniqueId: 'marketplace',
        requestField: 'marketplace'
      },
      isEditable: true
    },
    {
      header: t('marketplace.id'),
      name: 'marketplaceId',
      type: 'string',
      isEditable: true,
      required: true,
      excludeInUpdate: true
    },
    {
      header: t('product.offer'),
      name: 'productOffer',
      type: 'select',
      isEditable: true,
      sortable: false,
      excludeInFilter: true,
      excludeInUpdate: true,
      required: true,
      options: {
        url: config.PRODUCT_OFFER,
        selectUrlParser: prepareSelectUrlForProductOfferOptions,
        name: 'offerId',
        uniqueId: 'offerId',
        requestField: 'productOfferId',
        render: (data) => ProductOfferFromStructure({ offer: data })
      },
      render: ({ value }) =>
        value && value.product.name + ' (' + value.product.productId + ') | ' + value.country.name,
      defaultFlex: 1
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('marketplaces')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'marketplaceConnectionId'}
        tableId={'marketplaceConnection'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Marketplace;
