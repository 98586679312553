import React from 'react';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';

const MarketplaceOrder = () => {
  const urls = {
    list: config.MARKETPLACE_ORDER,
    create: config.MARKETPLACE_ORDER,
    update: config.MARKETPLACE_ORDER_WITH_ID,
    delete: config.MARKETPLACE_ORDER_WITH_ID
  };
  const resource = 'ORDER';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'marketplaceOrderId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('marketplace'),
      name: 'platform',
      type: 'string'
    },
    {
      header: t('transaction.id'),
      name: 'transactionId',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('original.transaction.id'),
      name: 'originalTransactionId',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('status'),
      name: 'orderStatus',
      type: 'string',
      isEditable: true
    },
    {
      header: t('account.id'),
      name: 'account.accountId',
      type: 'number',
      filterEditor: NumberFilter,
      render: ({ data }) =>
        data &&
        data.account &&
        data.account.accountId +
          ' (' +
          (data.account.email || data.account.phoneNumber || data.account.uuid) +
          ')'
    },
    {
      header: t('price'),
      name: 'orderItem.price',
      type: 'number',
      filterEditor: NumberFilter,
      render: ({ data }) => data && data.orderItem && data.orderItem.price
    },
    {
      header: t('currency'),
      name: 'orderItem.currency',
      type: 'select',
      render: ({ data }) =>
        data && data.orderItem && data.orderItem.currency && data.orderItem.currency.currencyName
    },
    {
      header: t('product.offer'),
      name: 'orderItem.offer',
      type: 'select',
      render: ({ data }) =>
        data &&
        data.orderItem &&
        data.orderItem.offer &&
        data.orderItem.offer.product.name +
          ' (' +
          data.orderItem.offer.product.productId +
          ') | ' +
          data.orderItem.offer.country.name,
      defaultFlex: 1
    },
    {
      header: t('marketplace.valid.from'),
      id: 'orderItem.marketplaceValidFrom',
      type: 'date',
      isEditable: false,
      render: ({ data }) => data && data.orderItem && data.orderItem.marketplaceValidFrom,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('marketplace.valid.to'),
      id: 'orderItem.marketplaceValidTo',
      type: 'date',
      isEditable: false,
      render: ({ data }) => data && data.orderItem && data.orderItem.marketplaceValidTo,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('marketplace.orders')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'marketplaceOrderId'}
        tableId={'marketplaceOrder'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default MarketplaceOrder;
