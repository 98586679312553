import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import config from '../../../config';
import { putRequest } from '../../../utils';

const ChangeUserPassword = ({ selectedRow, open, handleClose }) => {
  if (!open || Object.keys(selectedRow).length === 0) return;
  const [currPassword, setCurrPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorCurrPassword, setErrorCurrPassword] = useState(false);
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    severity: 'info',
    message: ''
  });
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert({
      open: false,
      severity: 'info',
      message: ''
    });
  };

  const handleChangeCurrPassword = (ev) => {
    setCurrPassword(ev.target.value);
    setErrorCurrPassword(false);
    setOpenAlert({
      open: false,
      severity: 'info',
      message: ''
    });
  };

  const handleChangeNewPassword = (ev) => {
    setNewPassword(ev.target.value);
    setErrorNewPassword(false);
    setOpenAlert({
      open: false,
      severity: 'info',
      message: ''
    });
  };

  const changePassword = () => {
    if (!currPassword || !newPassword) {
      !currPassword && setErrorCurrPassword(true);
      !newPassword && setErrorNewPassword(true);
      setOpenAlert({ open: true, severity: 'warning', message: t('inputField') });
      return;
    }
    putRequest(
      config.ACCESS_MANAGEMENT_USER_CHANGE_PASSWORD(selectedRow.user_id),
      {
        password: currPassword,
        new_password: newPassword
      },
      user.accessToken
    )
      .then(() => {
        setOpenAlert({ open: true, severity: 'success', message: t('successChangePassword') });
      })
      .catch((err) => {
        setOpenAlert({ open: true, severity: 'error', message: err.message });
      });
  };
  return (
    <>
      <Dialog
        scroll={'paper'}
        fullScreen={false}
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>{t('changeUserPassword')}</Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Stack
              component="form"
              sx={{
                width: '25ch',
                marginTop: '25px'
              }}
              spacing={2}
              noValidate
              autoComplete="off">
              <TextField
                error={errorCurrPassword}
                id="old-password"
                label={t('currPassword')}
                type="password"
                autoComplete="current-password"
                value={currPassword}
                onChange={handleChangeCurrPassword}
              />
              <TextField
                error={errorNewPassword}
                id="new-password"
                label={t('newPassword')}
                type="password"
                autoComplete="current-password"
                value={newPassword}
                onChange={handleChangeNewPassword}
              />
              <Button onClick={changePassword}>{t('changePassword')}</Button>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ height: '50px' }}>
          {openAlert.open && (
            <Alert
              onClose={handleCloseAlert}
              severity={openAlert.severity}
              sx={{ width: 'auto', marginRight: 'auto' }}>
              {openAlert.message}
            </Alert>
          )}
          <Button onClick={handleClose}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeUserPassword;
