import React from 'react';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';

const MarketplaceNotification = () => {
  const urls = {
    list: config.MARKETPLACE_NOTIFICATION
  };
  const resource = 'ORDER';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'marketplaceNotificationId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('marketplace'),
      name: 'platform',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('marketplace.order.id'),
      name: 'marketplaceOrderId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('notification.type'),
      name: 'notificationType',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('notification.sub.type'),
      name: 'marketplaceSubType',
      type: 'string',
      defaultFlex: 1
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss',
      defaultFlex: 1
    },
    {
      header: t('handled.successfully'),
      name: 'handledSuccessfully',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    }
  ];

  return (
    <>
      <Header title={t('marketplace.notification')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'marketplaceNotificationId'}
        tableId={'marketplaceNotificationId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default MarketplaceNotification;
