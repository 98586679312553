import React from 'react';
import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import NavBar from '../../components/NavBar/NavBar';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header title={t('home')} />
      <Toolbar disableCreating />
      <NavBar />
    </>
  );
};

export default MainPage;
