export default function ProductOfferFromStructure(data) {
  return (
    data &&
    data.offer &&
    data.offer.offerId +
      ' | ' +
      data.offer.product.name +
      ' (' +
      data.offer.product.productId +
      ') | ' +
      data.offer.country.name
  );
}
