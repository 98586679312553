import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useTranslation } from 'react-i18next';

const OneToManyDialog = ({ columns, data, open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose}>
      <DialogContent>
        <ReactDataGrid
          idProperty={'productId'}
          columns={columns}
          enableColumnAutosize={false}
          dataSource={data}
          showColumnMenuLockOptions={false}
          showColumnMenuGroupOptions={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OneToManyDialog;
