import React from 'react';
import config from '../../../../config';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import * as Yup from 'yup';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useTranslation } from 'react-i18next';

const PaymentSystem = () => {
  const urls = {
    list: config.PAYMENT_SYSTEM,
    create: config.PAYMENT_SYSTEM,
    update: config.PAYMENT_SYSTEM_WITH_ID,
    delete: config.PAYMENT_SYSTEM_WITH_ID
  };
  const resource = 'PAYMENT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'paymentSystemId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('name'),
      name: 'paymentSystemName',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Name is required'),
      required: true,
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('description'),
      name: 'paymentSystemDescription',
      type: 'string',
      validationSchema: Yup.string().max(255).required('Description is required'),
      required: true,
      isEditable: true,
      defaultFlex: 1
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('payment.systems')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'paymentSystemId'}
        tableId={'PaymentSystem'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default PaymentSystem;
