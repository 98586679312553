import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Table from './Table/Table';
import config from '../../config';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import HistoryPopover from './HistoryPopover';
import { useTranslation } from 'react-i18next';

const HistoryCard = ({ open, handleClose }) => {
  const urls = {
    list: config.LOGS
  };
  const { t } = useTranslation();
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={open} onClose={handleClose}>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Table
            uniqueId={'logId'}
            disableSidebar
            columns={[
              {
                header: t('author'),
                name: 'author',
                type: 'string'
              },
              {
                header: t('name'),
                name: 'entityName',
                type: 'string'
              },
              {
                header: t('id'),
                name: 'entityId',
                type: 'string'
              },
              {
                header: t('action'),
                name: 'action',
                type: 'string'
              },
              {
                header: t('changes'),
                name: 'changes',
                type: 'string',
                defaultFlex: 1,
                render: ({ value }) => <HistoryPopover value={value} />
              },
              {
                header: t('dateAdded'),
                name: 'performedAt',
                type: 'date',
                filterEditor: DateFilter,
                dateFormat: 'YYYY-MM-DDTHH:mm:ss'
              }
            ]}
            tableId={'history'}
            urls={urls}
            style={{ minHeight: '500px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryCard;
