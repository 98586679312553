import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, ListItemText, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRequest } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const SearchBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const filterByCards = (cards, key) => {
    const matches = cards.filter((card) => card.cardNumber.includes(key));
    return matches.length > 0;
  };

  const getOptionsDelayed = useCallback(
    debounce((value, callback) => {
      setOptions([]);
      if (value.length) {
        getRequest(
          config.ACCOUNT_SMART_SEARCH + '?limit=100&searchValue=' + value,
          user.accessToken
        ).then((res) => {
          callback(
            res.data.data.filter(
              (user) =>
                (user.email && user.email.includes(value)) ||
                (user.phoneNumber && user.phoneNumber.includes(value)) ||
                (user.paymentCards && filterByCards(user.paymentCards))
            )
          );
        });
      } else {
        callback([]);
      }
    }, 500),
    []
  );

  useEffect(() => {
    getOptionsDelayed(value, (filteredOptions) => {
      setOptions(filteredOptions);
    });
  }, [value, getOptionsDelayed]);

  return (
    <Autocomplete
      freeSolo
      sx={{ mr: 5, width: '400px' }}
      size={'small'}
      disableClearable
      id="free-solo-2-demo"
      options={options}
      inputValue={value}
      onInputChange={(e, newValue) => {
        setValue(newValue);
      }}
      getOptionLabel={(option) => option.accountId.toString()}
      filterOptions={(x) => x}
      renderOption={(props, option) => {
        return (
          <Box
            key={option.accountId}
            component="li"
            {...props}
            onClick={() => {
              navigate('/accounts/' + option.accountId);
            }}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 2, cursor: 'pointer' }}>
              {option.firstName &&
                option.firstName[0].toUpperCase() + option.lastName &&
                option.lastName[0].toUpperCase()}
            </Avatar>
            <ListItemText
              primary={option.firstName + ' ' + option.lastName}
              secondary={option.email + ' ' + option.phoneNumber}
            />
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('search')}
          InputProps={{
            ...params.InputProps,
            type: 'search'
          }}
        />
      )}
    />
  );
};

export default SearchBar;
