import React from 'react';
// import { getRequest } from '../../../utils';
import config from '../../../config';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import ReactDataGrid from '@inovua/reactdatagrid-community';
import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import NavBar from '../../components/NavBar/NavBar';
import { FaTools, FaKey } from 'react-icons/fa';
import UserCard from './UserCard';
import ChangeUserPassword from './ChangeUserPassword';
// import { useSelector } from 'react-redux';

const Users = () => {
  const urls = {
    list: config.ACCESS_MANAGEMENT_USER,
    create: config.ACCESS_MANAGEMENT_USER,
    update: config.ACCESS_MANAGEMENT_USER_WITH_ID,
    delete: config.ACCESS_MANAGEMENT_USER_WITH_ID
  };
  const resource = 'ACCESS';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'user_id',
      type: 'number',
      excludeInFilter: true,
      defaultFlex: 1
    },
    {
      header: t('first.name'),
      name: 'first_name',
      type: 'string',
      defaultFlex: 2,
      excludeInFilter: true,
      isEditable: true
    },
    {
      header: t('last.name'),
      name: 'last_name',
      type: 'string',
      defaultFlex: 2,
      excludeInFilter: true,
      isEditable: true
    },
    {
      header: t('email'),
      name: 'email',
      type: 'string',
      defaultFlex: 2,
      excludeInFilter: true,
      isEditable: true,
      disableUpdate: true
    },
    {
      header: t('password'),
      name: 'password',
      type: 'password',
      excludeInFilter: true,
      excludeInTable: true,
      isEditable: true,
      excludeInUpdate: true
    },
    {
      header: t('is.active'),
      name: 'is_active',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />,
      defaultFlex: 1,
      excludeInFilter: true,
      isEditable: true
    },
    {
      header: t('dateAdded'),
      name: 'date_added',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      excludeInFilter: true,
      defaultFlex: 2
    },
    {
      header: t('dateModify'),
      name: 'date_modified',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      excludeInFilter: true,
      defaultFlex: 2
    },
    {
      header: t('dateDelete'),
      name: 'date_deleted',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      excludeInFilter: true,
      defaultFlex: 2
    }
  ];

  const userCardGenerator = (selectedRow, openCard, handleClickOpenCard, handleCloseCard) => {
    return {
      toolTip: (
        <Tooltip title={t('user.settings')}>
          <IconButton onClick={handleClickOpenCard}>
            <FaTools fontSize={20} />
          </IconButton>
        </Tooltip>
      ),
      card: (
        <UserCard
          iamResource={resource}
          row={selectedRow}
          open={openCard}
          handleClose={handleCloseCard}
        />
      )
    };
  };

  const changePasswordCard = (
    selectedRow,
    openCustomCard,
    handleClickOpenCustomCard,
    handleCloseCustomCard
  ) => {
    return {
      toolTip: (
        <Tooltip title={t('changePassword')}>
          <IconButton onClick={handleClickOpenCustomCard}>
            <FaKey fontSize={20} />
          </IconButton>
        </Tooltip>
      ),
      card: (
        <ChangeUserPassword
          iamResource={resource}
          selectedRow={selectedRow}
          open={openCustomCard}
          handleClose={handleCloseCustomCard}
        />
      )
    };
  };

  return (
    <>
      <Header title={t('access.management.user')} />
      <Toolbar
        iamResource={resource}
        detailedCardGenerator={userCardGenerator}
        customCardGenerator={changePasswordCard}
      />
      <Table
        iamResource={resource}
        enableDelete
        uniqueId={'user_id'}
        tableId={'userId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Users;
