import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AsyncSelect from '../AsyncSelect';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { deleteRequest, postRequest, postRequestWithFiles, putRequest } from '../../../utils';
import { MuiFileInput } from 'mui-file-input';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PaginatedSelect from '../PaginatedSelect';
import fileDownload from 'js-file-download';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const defaultValue = {
  boolean: true,
  string: '',
  select: null,
  date: () => new Date()
};

const Sidebar = ({
  idProperty,
  show,
  onHide,
  selectedRow,
  customHeader,
  fields,
  updateDataSource,
  dataSourceState,
  disableUpdate,
  // disableDelete,
  isDeactivate,
  urls,
  enableDelete,
  permissions
}) => {
  const { creator, editor, admin } = permissions;
  const isDisableDelete = !(enableDelete && selectedRow.is_active);
  const mode = selectedRow && Object.keys(selectedRow).length;
  const user = useSelector((state) => state.user);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const disableInputFields = Boolean(mode && disableUpdate);
  const { t } = useTranslation();
  const fileFields = fields.filter((field) => field.type === 'file');
  const editableFields = fields
    .filter((field) => field.isEditable)
    .filter((field) => !(mode && field.excludeInUpdate))
    .filter((field) => !(!mode && field.excludeInCreate))
    .filter(
      (field) =>
        !(
          mode &&
          selectedRow?.productType?.productType !== 'SUBSCRIPTION' &&
          field.enableUpdateOnlySubscription
        )
    );
  const initialValues = editableFields.reduce((obj, field) => {
    if (mode) {
      const value =
        field.sideBarValue !== undefined
          ? field.sideBarValue(selectedRow)
          : selectedRow && selectedRow[field.name || field.id];
      obj[field.name || field.id] =
        value === undefined
          ? field.type === 'date'
            ? defaultValue[field.type]()
            : defaultValue[field.type]
          : value;
    } else {
      obj[field.name || field.id] =
        field.defaultValue !== undefined
          ? field.defaultValue
          : field.options && field.options.multiple
          ? []
          : field.type === 'date'
          ? defaultValue[field.type]()
          : defaultValue[field.type];
    }
    return obj;
  }, {});
  const validationSchema = editableFields.reduce((obj, field) => {
    if (field.validationSchema) {
      obj[field.name || field.id] = field.validationSchema;
    }
    return obj;
  }, {});

  const onDelete = () => {
    deleteRequest(urls.delete(selectedRow[idProperty]), user.accessToken).then(() => {
      updateDataSource(!dataSourceState);
      onHide();
    });
  };
  // TODO: remove logging
  return (
    <Offcanvas
      show={show}
      enforceFocus={false}
      onHide={onHide}
      placement={'end'}
      backdrop={false}
      scroll={false}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{mode ? t('update') : t('create')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Header className={'mt-0 pt-0'}>
        <Box sx={{ width: '100%' }}>{customHeader ? customHeader : ''}</Box>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Formik
            enableReinitialize
            initialValues={{
              ...initialValues,
              isUpdateBtn: false,
              submit: null
            }}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              const onCreate = (values) => {
                const prepareFormData = (values) => {
                  let data = new FormData();
                  fileFields.forEach((field) => {
                    const alias = field.name || field.id;
                    data.append(alias, values[alias]);
                    delete values[alias];
                  });
                  data.append('dumpedJsonBody', JSON.stringify(values));
                  return data;
                };
                const request =
                  fileFields.length >= 1 && urls.createFromFile
                    ? postRequestWithFiles(
                        urls.createFromFile || urls.create,
                        prepareFormData(values),
                        user.accessToken
                      )
                    : postRequest(urls.create, values, user.accessToken);
                request
                  .then((response) => {
                    if (urls.downloadFileFromResponse) {
                      const blob = new Blob([response.data]);
                      const filename = (response.headers['content-disposition'] || '').match(
                        /filename=\\?"(.*\..*)\\?"/
                      );
                      fileDownload(blob, filename ? filename[1] : 'download');
                    }

                    updateDataSource(!dataSourceState);
                    onHide();
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setErrors({
                      submit: err.response && err.response.data && err.response.data.message
                    });
                    setSubmitting(false);
                  });
              };

              const onUpdate = (values) => {
                putRequest(urls.update(selectedRow[idProperty]), values, user.accessToken)
                  .then(() => {
                    updateDataSource(!dataSourceState);
                    onHide();
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setErrors({
                      submit: err.response && err.response.data && err.response.data.message
                    });
                    setSubmitting(false);
                  });
              };
              // для активации деактивации ProductOffer офферов
              const onUpdateActivity = () => {
                const action = selectedRow.isPrimary ? '/deactivate' : '/reactivate';
                putRequest(urls.update(selectedRow[idProperty]) + action, {}, user.accessToken)
                  .then(() => {
                    updateDataSource(!dataSourceState);
                    onHide();
                  })
                  .catch((err) => {
                    setStatus({ success: false });
                    setErrors({
                      submit: err.response && err.response.data && err.response.data.message
                    });
                    setSubmitting(false);
                  });
              };
              try {
                const clonedValues = cloneDeep(values);
                setStatus({ success: false });
                setSubmitting(false);
                fields
                  .filter(
                    (field) =>
                      field.type === 'select' &&
                      field.isEditable &&
                      !(mode && field.excludeInUpdate)
                  )
                  .forEach((field) => {
                    if (field.options.multiple) {
                      if (clonedValues[field.name || field.id]?.length > 0) {
                        clonedValues[field.options.requestField] = clonedValues[
                          field.name || field.id
                        ].map((value) => {
                          if (typeof field.options.uniqueId === 'object') {
                            const result = {};
                            if (Array.isArray(field.options.uniqueId)) {
                              field.options.uniqueId.forEach((key) => (result[key] = value[key]));
                            } else {
                              Object.entries(field.options.uniqueId).forEach(
                                ([key, optionsValue]) => {
                                  const requestFiled =
                                    optionsValue &&
                                    optionsValue.options &&
                                    optionsValue.options.requestField;
                                  if (value[key]) {
                                    result[key] = requestFiled
                                      ? value[key][requestFiled]
                                      : value[key];
                                  }
                                }
                              );
                            }
                            return result;
                          } else {
                            return value[field.options.uniqueId];
                          }
                        });
                      }
                    } else {
                      if (clonedValues[field.name || field.id] === null) {
                        delete clonedValues[field.options.requestField];
                      } else {
                        if (
                          clonedValues[field.name || field.id][field.options.uniqueId] ||
                          clonedValues[field.name || field.id]
                        ) {
                          clonedValues[field.options.requestField] =
                            clonedValues[field.name || field.id][field.options.uniqueId] ||
                            clonedValues[field.name || field.id];
                        }
                      }
                    }
                    if (field.options.requestField !== (field.name || field.id)) {
                      delete clonedValues[field.name || field.id];
                    }
                  });
                fields
                  .filter((field) => field.type === 'date')
                  .forEach((field) => {
                    if (field.format) {
                      clonedValues[field.name || field.id] =
                        (clonedValues[field.name || field.id] &&
                          moment(clonedValues[field.name || field.id]).format('YYYY-MM-DD')) ||
                        (initialValues[field.name || field.id] &&
                          moment(initialValues[field.name || field.id]).format('YYYY-MM-DD'));
                    } else {
                      clonedValues[field.name || field.id] =
                        (clonedValues[field.name || field.id] &&
                          moment(clonedValues[field.name || field.id]).format()) ||
                        (initialValues[field.name || field.id] &&
                          moment(initialValues[field.name || field.id]).format());
                    }
                  });
                fields
                  .filter((field) => field.type === 'string')
                  .forEach((field) => {
                    if (field.excludeEmptyString && clonedValues[field.name || field.id] === '') {
                      delete clonedValues[field.name || field.id];
                    }
                  });
                if ([2, 3].includes(clonedValues['isFree'])) {
                  clonedValues['decimalPrice'] = 0;
                  clonedValues['trialDecimalPrice'] = null;
                  clonedValues['validFrom'] =
                    clonedValues['validFrom'] || clonedValues['price.validFrom'];
                  delete clonedValues['price.trialDecimalPrice'];
                  delete clonedValues['price.decimalPrice'];
                  delete clonedValues['price.validFrom'];
                  delete clonedValues['price'];
                } else if (clonedValues['isFree'] === 1) {
                  clonedValues['decimalPrice'] =
                    clonedValues['decimalPrice'] ||
                    (clonedValues['price'] && clonedValues['price']['decimalPrice']);
                  clonedValues['trialDecimalPrice'] =
                    clonedValues['trialDecimalPrice'] ||
                    (clonedValues['price'] && clonedValues['price']['trialDecimalPrice']);
                  clonedValues['validFrom'] =
                    clonedValues['validFrom'] || clonedValues['price.validFrom'];
                  delete clonedValues['price.trialDecimalPrice'];
                  delete clonedValues['price.decimalPrice'];
                  delete clonedValues['price.validFrom'];
                  delete clonedValues['price'];
                }
                delete clonedValues['submit'];
                delete clonedValues['isUpdateBtn'];
                urls.treatUpdateAsCreate === true
                  ? onCreate(clonedValues)
                  : mode
                  ? !values.isUpdateBtn
                    ? onUpdateActivity(clonedValues)
                    : onUpdate(clonedValues)
                  : onCreate(clonedValues);
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {editableFields.map((field) => {
                    if (field.type === 'string' || field.type === 'number') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <Stack spacing={1}>
                            <TextField
                              id={field.name || field.id}
                              label={field.header}
                              type={field.type === 'number' ? 'number' : 'text'}
                              inputProps={field.type === 'number' ? { min: 0 } : {}}
                              disabled={disableInputFields}
                              value={values[field.name || field.id]}
                              name={field.name || field.id}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              required={field.required}
                              error={Boolean(
                                touched[field.name || field.id] && errors[field.name || field.id]
                              )}
                            />
                            {touched[field.name || field.id] && errors[field.name || field.id] && (
                              <FormHelperText error id="helper-text-firstname-signup">
                                {errors[field.name || field.id]}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                      );
                    } else if (field.type === 'password') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            sx={{ width: '100%' }}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            disabled={disableInputFields}
                            value={values[field.name || field.id]}
                            name={field.name || field.id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            required={field.required}
                            error={Boolean(
                              touched[field.name || field.id] && errors[field.name || field.id]
                            )}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </Grid>
                      );
                    } else if (field.type === 'select') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <Stack spacing={1}>
                            {field.sideBarRender !== undefined ? (
                              field.sideBarRender(values, field, user, setFieldValue)
                            ) : field.options.selectUrlParser !== undefined ? (
                              <PaginatedSelect
                                field={field}
                                required={field.required}
                                readOnly={disableInputFields}
                                onChange={setFieldValue}
                                value={values[field.name || field.id]}
                              />
                            ) : (
                              <AsyncSelect
                                field={field}
                                required={field.required}
                                readOnly={disableInputFields}
                                onChange={setFieldValue}
                                value={values[field.name || field.id]}
                              />
                            )}
                            {touched[field.name || field.id] && errors[field.name || field.id] && (
                              <FormHelperText error id="helper-text-firstname-signup">
                                {errors[field.name || field.id]}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                      );
                    } else if (field.type === 'boolean') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <Stack spacing={1}>
                            <FormControlLabel
                              control={
                                <Switch
                                  id={field.name || field.id}
                                  checked={values[field.name || field.id]}
                                  onChange={handleChange}
                                  disabled={disableInputFields}
                                  onBlur={handleBlur}
                                  required={field.required}
                                />
                              }
                              label={field.header}
                            />
                            {touched[field.name || field.id] && errors[field.name || field.id] && (
                              <FormHelperText error id="helper-text-firstname-signup">
                                {errors[field.name || field.id]}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                      );
                    } else if (field.type === 'file') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <Stack spacing={1}>
                            <MuiFileInput
                              getInputText={(value) => (value ? value.name : 'Insert file')}
                              value={values[field.name || field.id]}
                              onChange={(file) => {
                                setFieldValue(field.name || field.id, file);
                              }}
                            />
                            {touched[field.name || field.id] && errors[field.name || field.id] && (
                              <FormHelperText error id="helper-text-firstname-signup">
                                {errors[field.name || field.id]}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                      );
                    } else if (field.type === 'date') {
                      return (
                        <Grid key={field.name || field.id} item xs={12}>
                          <Stack spacing={1}>
                            <DateTimePicker
                              label={field.header}
                              required={field.required}
                              disabled={disableInputFields}
                              value={values[field.name || field.id]}
                              format="yyyy-MM-dd'T'HH:mm:ss"
                              onChange={(newValue) => {
                                setFieldValue(field.name || field.id, newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            />
                            {touched[field.name || field.id] && errors[field.name || field.id] && (
                              <FormHelperText error id="helper-text-firstname-signup">
                                {errors[field.name || field.id]}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                  })}
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  {!(creator || editor || admin) ? (
                    ''
                  ) : (
                    <Grid item xs={12}>
                      <div className={'sidebar-footer'}>
                        {mode ? (
                          <>
                            {isDisableDelete ? (
                              isDeactivate ? (
                                <Button
                                  variant={'contained'}
                                  color={'primary'}
                                  className={'float-start ms-3'}
                                  type={'submit'}
                                  onClick={(e) => {
                                    setFieldValue('isUpdateBtn', false);
                                    handleSubmit(e);
                                  }}>
                                  {selectedRow.isPrimary ? t('disableSell') : t('makeMain')}
                                </Button>
                              ) : (
                                ''
                              )
                            ) : (
                              <Button
                                variant={'contained'}
                                color={'error'}
                                className={'float-start ms-3'}
                                onClick={() => {
                                  onDelete();
                                }}>
                                {t('delete')}
                              </Button>
                            )}
                            {disableUpdate ? (
                              ''
                            ) : (
                              <Button
                                disabled={isSubmitting}
                                variant={'contained'}
                                color={'primary'}
                                className={'float-end me-3'}
                                type={'submit'}
                                onClick={(e) => {
                                  setFieldValue('isUpdateBtn', true);
                                  handleSubmit(e);
                                }}>
                                {t('update')}
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            variant={'contained'}
                            color={'primary'}
                            className={'float-end me-3'}
                            type={'submit'}>
                            {t('create')}
                          </Button>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </LocalizationProvider>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Sidebar;
