import { Box, Typography } from '@mui/material';
import React from 'react';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3, height: '35vh' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
