import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const SettingsModal = ({ show, onHide }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0]);
  const { t } = useTranslation();

  const updateLanguage = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <Dialog open={show} onClose={onHide} maxWidth={'sm'} fullWidth>
      <DialogTitle>{t('settings')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item width={'50%'} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedLanguage}
                label={t('language')}
                fullWidth
                onChange={(event) => {
                  setSelectedLanguage(event.target.value);
                }}>
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'ru'}>Русский</MenuItem>
                <MenuItem value={'ua'}>Українська</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>{t('close')}</Button>
        <Button
          onClick={() => {
            updateLanguage();
            onHide();
          }}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsModal;
