import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { discountUnitRender, renamed } from '../../../../utils';

const PromoTypeDiscount = () => {
  const urls = {
    list: config.PRODUCT_DISCOUNT_TYPE,
    create: config.PRODUCT_DISCOUNT_TYPE,
    update: config.PRODUCT_DISCOUNT_TYPE_WITH_ID,
    delete: config.PRODUCT_DISCOUNT_TYPE_WITH_ID
  };
  const resource = 'PROMO_DISCOUNT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'productTypeDiscountId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('type'),
      name: 'productType',
      type: 'select',
      required: true,
      options: {
        url: config.PRODUCT_TYPE,
        name: 'productTypeName',
        uniqueId: 'productTypeId',
        requestField: 'productTypeId'
      },
      render: ({ value }) => value && value.productTypeName,
      isEditable: true
    },
    {
      header: t('country'),
      name: 'country',
      type: 'select',
      isEditable: true,
      required: true,
      options: {
        url: config.COUNTRY,
        name: 'name',
        uniqueId: 'countryId',
        requestField: 'countryId'
      },
      render: ({ value }) => value && value.name,
      defaultFlex: 1
    },
    {
      header: t('promo.code'),
      name: 'promoCode',
      type: 'select',
      isEditable: true,
      required: true,
      options: {
        url: config.PROMO,
        name: 'promoCode',
        uniqueId: 'promoCode',
        requestField: 'promoCode'
      },
      render: ({ value }) => value && value.promoCode,
      defaultFlex: 1
    },
    {
      header: t('discount.value'),
      name: 'discountValue',
      type: 'number',
      isEditable: true,
      required: true,
      filterEditor: NumberFilter
    },
    {
      header: t('discount.unit'),
      name: 'discountUnit',
      type: 'select',
      required: true,
      options: {
        data: [
          {
            discountUnit: 'CURRENCY'
          },
          {
            discountUnit: 'PERCENT'
          },
          {
            discountUnit: 'PRICE_OVERRIDE'
          }
        ],
        name: 'discountUnit',
        uniqueId: 'discountUnit',
        requestField: 'discountUnit',
        render: discountUnitRender
      },
      render: (option) => renamed[option.value],
      isEditable: true,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: '',
        dataSource: [
          {
            id: 'CURRENCY',
            label: renamed.CURRENCY,
            discountUnit: 'CURRENCY'
          },
          {
            id: 'PERCENT',
            label: renamed.PERCENT,
            discountUnit: 'PERCENT'
          },
          {
            id: 'PRICE_OVERRIDE',
            label: renamed.PRICE_OVERRIDE,
            discountUnit: 'PRICE_OVERRIDE'
          }
        ]
      }
    },
    {
      header: t('min.order'),
      name: 'minOrderValue',
      type: 'number',
      required: true,
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('max.order'),
      name: 'maxOrderValue',
      type: 'number',
      required: true,
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('activations.per.promo'),
      name: 'numberOfActivationsPerPromo',
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('max.discount'),
      name: 'maxDiscountAmount',
      type: 'number',
      isEditable: true,
      filterEditor: NumberFilter
    },
    {
      header: t('valid.from'),
      name: 'validFrom',
      type: 'date',
      required: true,
      isEditable: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('valid.to'),
      name: 'validTo',
      type: 'date',
      required: true,
      isEditable: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      filterEditor: BoolFilter,
      isEditable: true,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('product.type.discounts')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'productTypeDiscountId'}
        tableId={'productTypeDiscountId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default PromoTypeDiscount;
