import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Tab,
  Tabs
} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import IconButton from '@mui/material/IconButton';
import { FaDoorClosed, FaUserPlus, FaUser, FaDoorOpen } from 'react-icons/fa';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../components/TabPanel';
import { getRequest, postRequest, deleteRequest, getRole } from '../../../utils';
import config from '../../../config';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useSelector } from 'react-redux';

const GroupCard = ({ row, open, handleClose, iamResource }) => {
  if (!open || Object.keys(row).length === 0) return;
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupPermission, setGroupPermission] = useState([]);
  const [groupPermissionToAdd, setGroupPermissionToAdd] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.permissions[iamResource]);
  const permissions = getRole(role);

  const getListUserInGroup = () => {
    row.group_id &&
      getRequest(config.ACCESS_MANAGEMENT_LIST_USER_IN_GROUP(row.group_id), user.accessToken).then(
        (res) => {
          const listUserToAdd = res?.data?.filter((item) => item.is_active);
          setGroupData(listUserToAdd || []);
        }
      );
  };

  const getListGroupPermission = () => {
    row.group_id &&
      getRequest(
        config.ACCESS_MANAGEMENT_PERMISSION_GROUP_WITH_ID(row.group_id),
        user.accessToken
      ).then((res) => {
        const listGroupPermission = res.data.filter((item) => item.is_active);
        setGroupPermission(listGroupPermission);
      });
  };

  useEffect(() => {
    value === 0 && getListUserInGroup();
    value === 1 && getListUserToAdd();
    value === 2 && getListGroupPermission();
    value === 3 && getListPermissionToAdd();
  }, [value]);

  useEffect(() => {
    if (!open) {
      setValue(0);
      setUserListData([]);
      setGroupData([]);
      setGroupPermission([]);
      setGroupPermissionToAdd([]);
    }
  }, [open]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const _handleClose = () => {
    handleClose();
  };

  const deleteUserFromGroup = (data) => {
    deleteRequest(
      config.ACCESS_MANAGEMENT_DELETE_USER_FROM_GROUP(data.user_id, row.group_id),
      user.accessToken
    ).finally(() => getListUserInGroup());
  };

  const getListUserToAdd = async () => {
    // делаем выборку активных юзеров не добавленных в группу
    const listUser = await getRequest(config.ACCESS_MANAGEMENT_USER, user.accessToken).then(
      (res) => [...res.data]
    );
    const listUserInGroup = [];
    await getRequest(
      config.ACCESS_MANAGEMENT_LIST_USER_IN_GROUP(row.group_id),
      user.accessToken
    ).then((res) => {
      res.data.forEach((item) => {
        if (item.is_active && item.user.user_id) {
          listUserInGroup.push(item.user.user_id);
        }
      });
    });
    const listUserToAdd = listUser.filter(
      (item) => item.is_active && !listUserInGroup.includes(item.user_id)
    );
    setUserListData(listUserToAdd || []);
  };

  const addUserToGroup = (data) => {
    postRequest(
      config.ACCESS_MANAGEMENT_ADD_USER_TO_GROUP(row.group_id),
      {
        user_id: data.user_id,
        is_active: data.is_active
      },
      user.accessToken
    ).finally(() => {
      getListUserToAdd();
    });
  };

  const deletePermissionFromGroup = (data) => {
    deleteRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_DELETE_GROUP_PERMISSION(data.permission_id, row.group_id),
      user.accessToken
    ).finally(() => getListGroupPermission());
  };

  const getListPermissionToAdd = async () => {
    // делаем выборку активных доступов не добавленных в группу
    const listPermission = await getRequest(
      config.ACCESS_MANAGEMENT_PERMISSION,
      user.accessToken
    ).then((res) => [...res.data]);
    const listPermissionInGroup = [];
    await getRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_GROUP_WITH_ID(row.group_id),
      user.accessToken
    ).then((res) => {
      res?.data?.forEach((item) => {
        if (item.is_active && item.permission.permission_id) {
          listPermissionInGroup.push(item.permission.permission_id);
        }
      });
    });
    const listUserToAdd = listPermission.filter(
      (item) => item.is_active && !listPermissionInGroup.includes(item.permission_id)
    );
    setGroupPermissionToAdd(listUserToAdd || []);
  };

  const addPermissionToGroup = (data) => {
    postRequest(
      config.ACCESS_MANAGEMENT_ADD_PERMISSION_TO_GROUP(row.group_id),
      {
        permission_id: data.permission_id,
        is_active: data.is_active
      },
      user.accessToken
    ).finally(() => {
      getListPermissionToAdd();
    });
  };

  const gridStyle = { minHeight: isFullscreen ? '74vh' : '22vh' };
  return (
    <Dialog
      fullScreen={isFullscreen}
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={_handleClose}>
      <DialogActions>
        <Button onClick={changeFullscreen} hide>
          <IconButton>
            {!isFullscreen ? (
              <OpenInFullIcon color="primary" />
            ) : (
              <CloseFullscreenIcon color="primary" />
            )}
          </IconButton>
        </Button>
      </DialogActions>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example">
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('group.users')}
                icon={<FaUser />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('group.addUsers')}
                icon={<FaUserPlus />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('group.permissions')}
                icon={<FaDoorClosed />}
                iconPosition={'start'}
              />
              <Tab
                sx={{ minHeight: '40px' }}
                label={t('group.addPermissions')}
                icon={<FaDoorOpen />}
                iconPosition={'start'}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('group.name'),
                  name: 'group',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ value }) => {
                    return value.name;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('first.name'),
                  name: 'userFirstName',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.user.first_name;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('last.name'),
                  name: 'userLastName',
                  type: 'string',
                  defaultFlex: 2,
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.user.last_name;
                  },
                  isEditable: true
                },
                {
                  header: t('email'),
                  name: 'userEmail',
                  type: 'string',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.user.email;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('is.active'),
                  name: 'is_active',
                  type: 'boolean',
                  filterEditor: BoolFilter,
                  render: ({ value }) => <Switch disabled checked={value} />,
                  defaultFlex: 1,
                  excludeInFilter: true,
                  isEditable: true
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('dateModify'),
                  name: 'date_modified',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('dateDelete'),
                  name: 'date_deleted',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'user',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  render: ({ data }) => (
                    <Button onClick={() => deleteUserFromGroup(data.user)}>Delete</Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={groupData}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('first.name'),
                  name: 'first_name',
                  type: 'string',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('last.name'),
                  name: 'last_name',
                  type: 'string',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('email'),
                  name: 'email',
                  type: 'string',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'user_id',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  render: ({ data }) => <Button onClick={() => addUserToGroup(data)}>Add</Button>
                }
              ]}
              enableColumnAutosize={true}
              dataSource={userListData}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('group.name'),
                  name: 'group',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ value }) => {
                    return value.name;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('iamResource'),
                  name: 'iam_resource',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.permission.iam_resource;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('iamRole'),
                  name: 'iam_role',
                  type: 'number',
                  excludeInFilter: true,
                  render: ({ data }) => {
                    return data.permission.iam_role;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('is.active'),
                  name: 'is_active',
                  type: 'boolean',
                  filterEditor: BoolFilter,
                  render: ({ value }) => <Switch disabled checked={value} />,
                  defaultFlex: 1,
                  excludeInFilter: true,
                  isEditable: true
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'permission',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  render: ({ value }) => (
                    <Button onClick={() => deletePermissionFromGroup(value)}>Delete</Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={groupPermission}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ReactDataGrid
              style={gridStyle}
              idProperty={'group'}
              columns={[
                {
                  header: t('group.name'),
                  name: 'group',
                  type: 'number',
                  excludeInFilter: true,
                  render: () => {
                    return row.name;
                  },
                  defaultFlex: 2
                },
                {
                  header: t('iamResource'),
                  name: 'iam_resource',
                  type: 'number',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('iamRole'),
                  name: 'iam_role',
                  type: 'number',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: t('is.active'),
                  name: 'is_active',
                  type: 'boolean',
                  filterEditor: BoolFilter,
                  render: ({ value }) => <Switch disabled checked={value} />,
                  defaultFlex: 1,
                  excludeInFilter: true,
                  isEditable: true
                },
                {
                  header: t('dateAdded'),
                  name: 'date_added',
                  type: 'date',
                  format: 'date',
                  filterEditor: DateFilter,
                  dateFormat: 'YYYY-MM-DD HH:mm',
                  excludeInFilter: true,
                  defaultFlex: 2
                },
                {
                  header: '',
                  name: 'permission_id',
                  type: 'string',
                  defaultVisible: permissions.editor,
                  render: ({ data }) => (
                    <Button onClick={() => addPermissionToGroup(data)}>Add</Button>
                  )
                }
              ]}
              enableColumnAutosize={true}
              dataSource={groupPermissionToAdd}
              showColumnMenuLockOptions={false}
              showColumnMenuGroupOptions={false}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupCard;
