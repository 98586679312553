import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRow, setUser } from '../../../actions';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { cacheManager, getRequest, getRole } from '../../../utils';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EMPTY_STRING = '';
const gridStyle = { minHeight: 'calc(100vh - 145px)' };

const defaultOperators = {
  number: 'eq',
  string: 'contains',
  boolean: 'neq',
  select: 'inlist',
  date: 'after'
};

const defaultValues = {
  number: 0,
  string: EMPTY_STRING,
  boolean: EMPTY_STRING,
  select: EMPTY_STRING,
  date: EMPTY_STRING
};

const processColumns = (tableId, columns) => {
  const config = cacheManager.get(tableId);
  return columns
    .filter((column) => !column.excludeInTable)
    .map((column) => {
      return {
        ...column,
        visible:
          config && config.invisible ? !config.invisible.includes(column.name || column.id) : true
      };
    });
};

const processColumnOrder = (tableId, columns) => {
  const config = cacheManager.get(tableId);
  return (
    (config && config.order) ||
    columns.filter((column) => !column.excludeInTable).map((column) => column.name || column.id)
  );
};

const Table = ({
  uniqueId,
  tableId,
  columns,
  urls,
  style,
  disableSidebar,
  disableUpdate,
  disableDelete,
  customSidebarHeader,
  isDeactivate,
  enableDelete,
  iamResource
}) => {
  const [selected, setSelected] = useState(null);
  const [tableColumns, setTableColumns] = useState(processColumns(tableId, columns));
  const [columnOrder, setColumnOrder] = useState(processColumnOrder(tableId, columns));
  const [isOpened, setIsOpened] = useState(false);
  const selectedRow = useSelector((state) => state.selectedRow);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const role = useSelector((state) => state.permissions[iamResource]);
  const permissions = getRole(role);

  const loadData = ({ skip, limit, sortInfo, _, filterValue }) => {
    _;
    const activeFilters = filterValue.filter((filter) => filter.value);
    const sortColumn = sortInfo && tableColumns.find((column) => column.name === sortInfo.name);
    return getRequest(
      urls.list +
        '?skip=' +
        skip +
        '&limit=' +
        limit +
        (sortInfo
          ? '&sortInfo=' +
            JSON.stringify({
              name:
                sortColumn.type === 'select' && sortColumn.options && sortColumn.options.url
                  ? (sortColumn.filterName || sortColumn.name) + '.' + sortColumn.options.name
                  : sortColumn.filterName || sortColumn.name,
              dir: sortInfo.dir
            })
          : '') +
        (activeFilters.length
          ? '&filterValue=' +
            JSON.stringify(
              activeFilters
                .map((filter) => {
                  const current = tableColumns.find(
                    (column) => (column.name || column.id) === filter.name
                  );
                  if (current.type === 'select' && current.options && current.options.url) {
                    return {
                      ...filter,
                      // TODO: fix one level nesting
                      name: filter.name + '.' + current.options.name
                    };
                  }
                  return {
                    ...filter,
                    name: filter.filterName || filter.name
                  };
                })
                .filter((filter) => filter.active !== false)
            )
          : ''),
      user.accessToken
    )
      .then((response) => {
        const { data, generalRecordsCount } = response.data;
        if (data === null) {
          return { data: [], count: 0 };
        }
        if (data && generalRecordsCount) {
          return { data: data || [], count: generalRecordsCount };
        }
        return {
          data: response.data,
          count: response.data.length
        };
      })
      .catch((err) => {
        if (
          err.response.data &&
          (err.response.data.message === 'Signature verification failed' ||
            err.response.data.message === 'Signature has expired')
        ) {
          return dispatch(setUser(null));
        }
        if (err.response.data && err.response.status === 422) {
          return [];
        }
        return [];
      });
  };
  const [dataSourceState, updateDataSource] = useState(false);
  const dataSource = useCallback(loadData, [dataSourceState]);
  const filterValues = tableColumns.map((column) => {
    if (column?.excludeInFilter) return {};
    return {
      name: column.name || column.id,
      type: column.type,
      operator: column.name === uniqueId && params[uniqueId] ? 'eq' : defaultOperators[column.type],
      value:
        column.name === uniqueId && params[uniqueId]
          ? Number.parseInt(params[uniqueId])
          : defaultValues[column.type]
    };
  });

  const onSelectionChange = useCallback((p) => {
    setSelected(p.selected);
    dispatch(setSelectedRow(p.data));
    setIsOpened(true);
  }, []);

  const onHide = () => {
    dispatch(setSelectedRow(null));
    setSelected(null);
    setIsOpened(false);
  };

  const onColumnVisibleChange = useCallback(({ column, visible }) => {
    const config = cacheManager.get(tableId) || {};
    const invisibleColumns = config.invisible || [];
    if (visible) invisibleColumns.splice(invisibleColumns.indexOf(column.name || column.id), 1);
    else invisibleColumns.push(column.name || column.id);
    const updatedTableColumns = tableColumns.map((tableColumn) => {
      return {
        ...tableColumn,
        visible: !invisibleColumns.includes(tableColumn.name || tableColumn.id)
      };
    });
    setTableColumns(updatedTableColumns);
    cacheManager.set(tableId, {
      ...config,
      invisible: invisibleColumns
    });
  }, []);

  useEffect(() => {
    cacheManager.set(tableId, {
      ...cacheManager.get(tableId),
      order: columnOrder
    });
  }, [columnOrder]);

  useEffect(() => {
    if (selectedRow && !Object.keys(selectedRow).length) {
      setIsOpened(true);
    }
  }, [selectedRow]);

  const emptyText = (
    <b
      style={{
        padding: 15,
        border: '3px solid #7986cb',
        color: '#d5752e',
        borderRadius: 7,
        fontSize: '30px'
      }}>
      {t('noContents')}
    </b>
  );

  const translations = {
    sortAsc: t('sort.asc'),
    sortDesc: t('sort.desc'),
    pageText: t('page'),
    ofText: t('of.text'),
    perPageText: t('per.page'),
    showingText: t('showing'),
    clearAll: t('clear.all'),
    clear: t('clear'),
    showFilteringRow: t('show.filtering.row'),
    hideFilteringRow: t('hide.filtering.row'),
    disable: t('disable'),
    enable: t('enable'),
    unsort: t('unsort'),
    columns: t('columns'),
    contains: t('contains'),
    startsWith: t('starts.with'),
    endsWith: t('ends.with'),
    notContains: t('not.contains'),
    neq: t('neq'),
    eq: t('eq'),
    notEmpty: t('not.empty'),
    empty: t('empty'),
    lt: t('lt'),
    lte: t('lte'),
    gt: t('gt'),
    gte: t('gte')
  };

  return (
    <div
      className={
        'table-billing ' +
        (isOpened &&
        !disableSidebar &&
        permissions.editor &&
        permissions.creator &&
        permissions.admin
          ? 'opened'
          : '')
      }>
      <ReactDataGrid
        style={style || gridStyle}
        i18n={translations}
        idProperty={uniqueId}
        columns={tableColumns}
        selected={selected}
        defaultFilterValue={filterValues}
        enableColumnAutosize={false}
        columnOrder={columnOrder}
        onColumnOrderChange={setColumnOrder}
        dataSource={dataSource}
        dataSources={dataSource}
        pagination={'remote'}
        showColumnMenuLockOptions={false}
        showColumnMenuGroupOptions={false}
        onColumnVisibleChange={onColumnVisibleChange}
        enableSelection={true}
        onSelectionChange={onSelectionChange}
        editable={true}
        emptyText={emptyText}
      />
      {isOpened &&
        selectedRow &&
        !disableSidebar &&
        (permissions.editor || permissions.creator || permissions.admin) && (
          <Sidebar
            key={JSON.stringify(selectedRow)}
            idProperty={uniqueId}
            show={selectedRow}
            onHide={onHide}
            selectedRow={selectedRow}
            dataSourceState={dataSourceState}
            updateDataSource={updateDataSource}
            customHeader={customSidebarHeader}
            fields={columns}
            disableUpdate={disableUpdate || !permissions.editor}
            disableDelete={disableDelete || !permissions.admin}
            isDeactivate={isDeactivate}
            urls={urls}
            enableDelete={enableDelete && permissions.admin}
            permissions={permissions}
          />
        )}
    </div>
  );
};

export default Table;
