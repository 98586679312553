import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import store, { saveState } from './store';
import moment from 'moment';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material';
import { debounce } from 'lodash';
import { ConfirmDialogProvider } from 'react-mui-confirm';

store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
window.moment = moment;
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#20bec6',
      contrastText: '#fff'
    },
    error: {
      main: '#d8271c',
      contrastText: '#fff'
    }
  }
});
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConfirmDialogProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfirmDialogProvider>
    </ThemeProvider>
  </Provider>
);
