import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const permissions = useSelector((state) => state.permissions);
  // console.log('permissions', permissions);
  // const isPermissions = Object.keys(permissions).length > 0;
  return user && permissions ? children : <Navigate replace to={'/login'} />;
};

export default ProtectedRoute;
