import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductOfferFromStructure from '../../../components/ProductOfferFromStructure';
import * as Yup from 'yup';
import { prepareSelectUrlForProductOfferOptions } from '../../../../utils';

const SpecialPrice = () => {
  const urls = {
    list: config.SPECIAL_OFFER_PRICE,
    create: config.SPECIAL_OFFER_PRICE,
    update: config.SPECIAL_OFFER_PRICE_WITH_ID,
    delete: config.SPECIAL_OFFER_PRICE_WITH_ID
  };
  const resource = 'SYSTEM';
  const { t } = useTranslation();
  const arrayOfIntegersRegex = /\d+(?:\s*|\s*,\s*)/g;

  const renderPrice = (price) => {
    return (
      price &&
      price.priceId +
        ' (' +
        price.decimalPrice +
        ', ' +
        (price.trialDecimalPrice || '') +
        ', ' +
        (price.currency && price.currency.currencyCode) +
        ')'
    );
  };

  const columns = [
    {
      header: t('id'),
      name: 'accountOfferPriceId',
      id: 'accountOfferPriceId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('product.offer'),
      name: 'offer',
      type: 'select',
      isEditable: true,
      excludeInUpdate: true,
      options: {
        url: config.PRODUCT_OFFER,
        name: 'offer',
        selectUrlParser: prepareSelectUrlForProductOfferOptions,
        uniqueId: 'offerId',
        requestField: 'offerId',
        // TODO render
        render: (data) => ProductOfferFromStructure({ offer: data })
      },
      render: ({ data }) => ProductOfferFromStructure(data)
    },
    {
      header: t('account.id'),
      name: 'account',
      type: 'number',
      filterEditor: NumberFilter,
      render: ({ data }) =>
        data &&
        data.account &&
        data.account.accountId +
          ' (' +
          (data.account.email || data.account.phoneNumber || data.account.uuid) +
          ')'
    },
    {
      header: t('price.id'),
      // TODO: fix sorting
      name: 'price',
      type: 'number',
      render: ({ data }) => data && data.price && renderPrice(data.price),
      filterEditor: NumberFilter
    },
    {
      header: t('planned.price.id'),
      name: 'plannedPrice',
      type: 'string',
      render: ({ data }) => data && data.plannedPrice && renderPrice(data.plannedPrice),
      filterEditor: NumberFilter
    },
    {
      header: t('valid.from'),
      id: 'validFrom',
      type: 'date',
      isEditable: true,
      required: true,
      excludeInUpdate: true,
      render: ({ data }) => data && data.validFrom,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('valid.to'),
      id: 'validTo',
      type: 'date',
      isEditable: true,
      excludeInUpdate: true,
      render: ({ data }) => data && data.validTo,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('price'),
      name: 'decimalPrice',
      excludeInTable: true,
      required: true,
      excludeInUpdate: true,
      isEditable: true,
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('trial.price'),
      name: 'trialDecimalPrice',
      excludeInTable: true,
      excludeInUpdate: true,
      isEditable: true,
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('account.ids'),
      name: 'accountIds',
      type: 'string',
      excludeInTable: true,
      excludeInUpdate: true,
      isEditable: true,
      validationSchema: Yup.string('Must be a string')
        .matches(arrayOfIntegersRegex)
        .required('Account ids is required')
    },
    {
      header: t('override.with.offer.price.change'),
      name: 'overrideWithOfferPriceChange',
      type: 'boolean',
      isEditable: true,
      excludeInTable: true,
      excludeInUpdate: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      defaultValue: false,
      isEditable: true,
      excludeInCreate: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('special.prices')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'accountOfferPriceId'}
        tableId={'specialPrices'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default SpecialPrice;
