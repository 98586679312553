import React from 'react';
import config from '../../../config';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import NavBar from '../../components/NavBar/NavBar';

const Permissions = () => {
  const urls = {
    list: config.ACCESS_MANAGEMENT_PERMISSION,
    create: config.ACCESS_MANAGEMENT_PERMISSION,
    delete: config.ACCESS_MANAGEMENT_PERMISSION_DELETE
  };
  const resource = 'ACCESS';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'permission_id',
      type: 'number',
      defaultFlex: 1
    },
    {
      header: t('IamResource'),
      name: 'iam_resource',
      type: 'string',
      defaultFlex: 1,
      isEditable: true
    },
    {
      header: t('iamRole'),
      name: 'iam_role',
      type: 'string',
      defaultFlex: 1,
      isEditable: true
    },
    {
      header: t('is.active'),
      name: 'is_active',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />,
      defaultFlex: 1,
      isEditable: true
    },
    {
      header: t('dateAdded'),
      name: 'date_added',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm',
      defaultFlex: 1
    },
    {
      header: t('dateModify'),
      name: 'date_modified',
      format: 'date',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      defaultFlex: 1
    },
    {
      header: t('dateDelete'),
      name: 'date_deleted',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm',
      defaultFlex: 1
    }
  ];

  return (
    <>
      <Header title={t('access.management.permission')} />
      <Toolbar iamResource={resource} />
      <Table
        enableDelete
        disableUpdate
        iamResource={resource}
        uniqueId={'permission_id'}
        tableId={'permissionId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Permissions;
