import React from 'react';
import config from '../../../../config';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { Switch } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const SocialNetwork = () => {
  const urls = {
    list: config.SOCIAL_NETWORK_MAPPING,
    create: config.SOCIAL_NETWORK_MAPPING,
    update: config.SOCIAL_NETWORK_MAPPING_WITH_ID,
    delete: config.SOCIAL_NETWORK_MAPPING_WITH_ID
  };
  const resource = 'ACCOUNT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'authProviderId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('country'),
      type: 'select',
      isEditable: true,
      validationSchema: Yup.mixed().required('Country is required'),
      defaultFlex: 1,
      name: 'country',
      options: {
        url: config.COUNTRY,
        name: 'name',
        uniqueId: 'countryId',
        requestField: 'countryId'
      },
      required: true,
      render: ({ value }) => value && value.name
    },
    {
      header: t('social.network'),
      type: 'select',
      isEditable: true,
      defaultFlex: 1,
      validationSchema: Yup.mixed().required('Social Network is required'),
      name: 'authProvider',
      required: true,
      options: {
        data: [
          {
            authProvider: 'APPLE'
          },
          {
            authProvider: 'GOOGLE'
          },
          {
            authProvider: 'FACEBOOK'
          }
        ],
        name: 'authProvider',
        uniqueId: 'authProvider',
        requestField: 'authProvider'
      }
    },
    {
      header: t('registration.allowed'),
      name: 'registrationAllowed',
      type: 'boolean',
      filterEditor: BoolFilter,
      isEditable: true,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  return (
    <>
      <Header title={t('social.network.mapping')} />
      <Toolbar iamResource={resource} />
      <Table
        iamResource={resource}
        uniqueId={'authProviderId'}
        tableId={'SocialNetworkMapping'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default SocialNetwork;
