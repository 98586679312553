import React from 'react';
import config from '../../../config';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import NavBar from '../../components/NavBar/NavBar';
import { FaTools } from 'react-icons/fa';
import GroupCard from './GroupCard';

const Users = () => {
  const urls = {
    list: config.ACCESS_MANAGEMENT_GROUP,
    create: config.ACCESS_MANAGEMENT_GROUP,
    update: config.ACCESS_MANAGEMENT_GROUP_UPDATE,
    delete: config.ACCESS_MANAGEMENT_GROUP_DEACTIVATE
  };
  const resource = 'ACCESS';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'group_id',
      type: 'number',
      defaultFlex: 1,
      excludeInFilter: true
    },
    {
      header: t('name'),
      name: 'name',
      type: 'string',
      defaultFlex: 1,
      isEditable: true,
      excludeInFilter: true
    },
    {
      header: t('is.active'),
      name: 'is_active',
      type: 'boolean',
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />,
      defaultFlex: 1,
      isEditable: true,
      // excludeInUpdate: true,
      // excludeInCreate: true,
      excludeInFilter: true
    },
    {
      header: t('dateAdded'),
      name: 'date_added',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      defaultFlex: 1,
      excludeInUpdate: true,
      excludeInCreate: true,
      excludeInFilter: true
    },
    {
      header: t('dateModify'),
      name: 'date_modified',
      format: 'date',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      defaultFlex: 1,
      excludeInUpdate: true,
      excludeInCreate: true,
      excludeInFilter: true
    },
    {
      header: t('dateDelete'),
      name: 'date_deleted',
      type: 'date',
      format: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DD HH:mm',
      defaultFlex: 1,
      excludeInUpdate: true,
      excludeInCreate: true,
      excludeInFilter: true
    }
  ];

  const groupCardGenerator = (
    selectedRow,
    openCard,
    handleClickOpenAccountCard,
    handleCloseAccountCard
  ) => {
    return {
      toolTip: (
        <Tooltip title={t('group.settings')}>
          <IconButton onClick={handleClickOpenAccountCard}>
            <FaTools fontSize={20} />
          </IconButton>
        </Tooltip>
      ),
      card: (
        <GroupCard
          iamResource={resource}
          row={selectedRow}
          open={openCard}
          handleClose={handleCloseAccountCard}
        />
      )
    };
  };

  return (
    <>
      <Header title={t('access.management.group')} />
      <Toolbar iamResource={resource} detailedCardGenerator={groupCardGenerator} />
      <Table
        enableDelete
        iamResource={resource}
        uniqueId={'group_id'}
        tableId={'groupId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Users;
