import React from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Button, FormHelperText, Grid, Stack, TextField } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { jwtDecode } from 'jwt-decode';

// project import
import AnimateButton from '../../AnimateButton';

// assets
import { useDispatch } from 'react-redux';
import { setUser, setOpenedTables, setPermissions } from '../../../../actions';
import axios from 'axios';
import config from '../../../../config';
import { getRequest } from '../../../../utils';
import cheatPermissionList from './cheatPermissionList';

const AuthLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(setOpenedTables([]));

  const getPermission = async (token) => {
    const cheat = false; // set true for full UI admin access without request
    if (cheat) {
      dispatch(setPermissions(cheatPermissionList));
      return;
    }
    const { sub } = jwtDecode(token);
    return getRequest(
      config.ACCESS_MANAGEMENT_PERMISSION_GET_USER_DIRECT_PERMISSION(sub),
      token
    ).then((res) => {
      if (res?.data?.length > 0) {
        const permissionList = {};
        res.data.forEach((item) => {
          permissionList[item.iam_resource] = item.iam_role;
        });
        return dispatch(setPermissions(permissionList));
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setStatus({ success: false });
          setSubmitting(false);
          axios
            .post(config.LOGIN, {
              username: values.email,
              password: values.password
            })
            .then(async (res) => {
              await getPermission(res.data.accessToken);
              dispatch(
                setUser({
                  ...res.data,
                  role: res.data.userType
                })
              );
              navigate('/');
            })
            .catch((err) => {
              setStatus({ success: false });
              setErrors({
                submit:
                  (err.response && err.response.data && err.response.data.message) || err.message
              });
              setSubmitting(false);
            });
        }}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    id="email-login"
                    label={'Email Address'}
                    variant="outlined"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter email address"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={'password'}
                    value={values.password}
                    name="password"
                    label={'Password'}
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter password"
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}></Stack>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary">
                    Login
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthLogin;
